/*eslint-disable*/
import { nodeName } from "jquery";
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function FooterEng() {
  return (
    <footer style={{backgroundColor:"#013C4E"}} className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul style={{display: "flex"}}>
              <li>
                <a
                  href="/"
                  style={{fontSize: 14, fontWeight: 400, textTransform: "none", color: "#fff"}}
                >
                  Grupo SEB
                </a>
              </li>
              <li>
                <a
                  href="/conduta"
                  style={{fontSize: 14, fontWeight: 400, textTransform: "none", color: "#fff"}}
                >
                  Conduct and Ethics
                </a>
              </li>

              <li>
                <a
                  href="/uniseb"
                  style={{fontSize: 14, fontWeight: 400, textTransform: "none", color: "#fff"}}
                >
                  UniSEB
                </a>
              </li>
              
              <li>
                <a
                  href="https://logounidadesseb.sebsa.com.br/relatorio-lei-igualdade-salarial/relatorio/33268567000364.html"
                  style={{fontSize: 14, fontWeight: 400, textTransform: "none", color: "#fff"}}
                  target="_blank"
                >
                  Salary Equality Report
                </a>
              </li>
            
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright" style={{fontWeight: 400, fontSize: 14, color: "#fff"}}>
              © {new Date().getFullYear()} Copyright. Grupo SEB.
            </span>
          </div>
          <br/>&nbsp;
          <br/>&nbsp;
          <br/>&nbsp;
          <br/>&nbsp;
          <br/>&nbsp;
        
        </Row>
      </Container>
    </footer>
  );
}

export default FooterEng;
