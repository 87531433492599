import React from "react";
// plugin that creates slider
import Logos from "../../assets/img/logos.png";
import imgAcelera from "../../assets/img/img_acelera.png";
import Empresa1 from "../../assets/img/empresa_01.png";
import Empresa2 from "../../assets/img/empresa_02.png";
import Empresa3 from "../../assets/img/empresa_03.png";
import Empresa4 from "../../assets/img/empresa_04.png";
import Empresa5 from "../../assets/img/empresa_05.png";
import Empresa6 from "../../assets/img/empresa_06.png";
import Empresa7 from "../../assets/img/empresa_07.png";
import Empresa8 from "../../assets/img/empresa_08.png";
import Empresa9 from "../../assets/img/empresa_09.png";
import Empresa10 from "../../assets/img/empresa_10.png";
import Empresa11 from "../../assets/img/empresa_11.png";
import Empresa12 from "../../assets/img/empresa_12.png";
import Empresa13 from "../../assets/img/empresa_13.png";
import Empresa14 from "../../assets/img/empresa_14.jpg";


// reactstrap components
import {
  Container,
  Row,
  Col,
  Button, Card, CardImg, CardBody, CardTitle, CardText
} from "reactstrap";

function MainEng() {
  
  const html = document.querySelector("html");
  html.setAttribute("lang", "en-us");
 
  return (
    <>
      <div id="quemsomos">
      <Container>
                <div style={{textAlign:"center", marginTop:50, marginBottom:50}}>
        <img src={Logos} width="90%" />
        </div>
        </Container>
<hr style={{borderWidth:2}} />
        <Container>


     
          <div>          
            <Row>
            <Col sm>
              <div  style={{fontSize:14, fontWeight:400, lineHeight:2,}}>
              We are focused on disruptive and innovative pedagogical proposals that develop different skills in our students, preparing them for the challenges of the future, anywhere in the world! <br /><br />

              Today, we are a team of more than 6,000 transformation agents who actively participate in the lives of more than 350,000 students in 30 countries, whether working in our own schools and franchises or developing innovative solutions at Conexia, our edtech. <br /><br />

              Our purpose is to transform people through education. Because we believe that curious people, with a critical sense, creativity and autonomy, anchored in human values and continuous learning, are able to transform the world. <br /><br />

              For this reason, we are bold and grow at an accelerated pace, investing heavily in digitalization, internationalization and in the premiumization or differentiation of all our operations, aimed at different teaching segments and different socioeconomic classes.<br /><br />



              </div>
              </Col>
              <Col sm>
              <div  style={{fontSize:14, fontWeight:400, lineHeight:2, marginBottom:20,}}>
              We are very proud of our origins. We were born from the entrepreneurship of our founders, a couple of visionary educators who always put the quality of teaching first and saw the school as a potential agent of integration of different experiences and cultures. It's no wonder that in our more than 50 years, students are at the heart of all our decisions, and we invest heavily in technology and intrapreneurship as allies to the best experience for students and families.
                </div>
              <img src={imgAcelera} width="90%" />
              </Col>
            </Row>  
          </div>         
        </Container>
      </div>

   
      <div style={{backgroundColor:"#02CFAA", paddingTop:10, paddingBottom:50, marginTop:-20}}>
        <Container>    
          <div style={{marginTop:30}}>      

          <div style={{marginBottom:30}}>
    <Container>
    <div className="title" style={{fontSize:38, textAlign:"center", lineHeight:1.3, paddingTop:0}}>
      <span style={{fontWeight:550, color:"#fff"}}>Come transform lives<br />
and change the world with us!

</span>
    </div>
    <div style={{fontSize:18, textAlign:"center", lineHeight:1.3, color:"#fff"}}>
      <span style={{fontWeight:400,}}>Do you want to be part of our group and have endless opportunities to learn and grow with us? Check out our opportunities:
</span>
    </div>
    </Container>  
</div>   
            <Row>
              <Col sm>
              <div  style={{fontSize:20, fontWeight:600, color:"#fff", textAlign:"center", marginBottom:20}}>
              <i className="nc-icon nc-bulb-63" style={{fontSize:50}} /><br />
              Opportunities
              </div>
              
              <div style={{textAlign:"center", marginTop:20,}}>
              <Button
                className="btn-round"
                style={{color:"#fff", width:"100%", borderColor:"#fff", fontSize:14, textTransform:"none"}}
                href="https://gruposeb.gupy.io/"
                target="_Blank"
                outline
              >
               Click here
                </Button>
                <br />&nbsp;
              </div>
              </Col>

              <Col sm>
              <div  style={{fontSize:20, fontWeight:600, color:"#fff", textAlign:"center", marginBottom:20}}>
              <i className="nc-icon nc-spaceship" style={{fontSize:50}} /><br />
              Young Talents
              </div>
          
             
              <div style={{textAlign:"center", marginTop:20,}}>
              <Button
                className="btn-round"
                style={{color:"#fff", width:"100%", borderColor:"#fff", fontSize:14, textTransform:"none"}}
                href="https://jovenstalentos.sebsa.com.br"
                target="_Blank"
                outline
              >
               Click here
                </Button>
                <br />&nbsp;
              </div>
              </Col>

              <Col sm>
              <div  style={{fontSize:20, fontWeight:600, color:"#fff", textAlign:"center", marginBottom:20}}>
              <i className="nc-icon nc-world-2" style={{fontSize:50}} /><br />
              Internship program
              </div>
      
              <div style={{textAlign:"center", marginTop:20,}}>
                <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", borderColor:"#fff", fontSize:14, textTransform:"none"}}
                  href="https://programadeestagio.sebsa.com.br"
                  target="_Blank"
                  outline
                >
                 Click here
                </Button>
                <br />&nbsp;
              </div>
              </Col>

            </Row>  
          </div>         
        </Container>
      </div>

<div id="marcas">
    <Container>
    <div className="title" style={{fontSize:38, textAlign:"center", lineHeight:1.3, paddingTop:20}}>
      <span style={{fontWeight:550, color:"#013C4E"}}>Check out our brands!
</span>
    </div>
    </Container>  
</div>
<hr style={{borderWidth:2, marginBottom:30}} />

<div style={{marginBottom:30}}>
    <Container>
    <div className="title" style={{fontSize:38, textAlign:"center", lineHeight:1.3, paddingTop:0}}>
      <span style={{fontWeight:550, color:"#013C4E"}}>Franchises</span>
    </div>
    <div style={{fontSize:18, textAlign:"center", lineHeight:1.3,}}>
      <span style={{fontWeight:400,}}>More than 550 bilingual educational institutions in 30 countries.</span>
    </div>
    </Container>  
</div>

<div>
  <Container>
    <div>     
      <Row>
        <Col sm style={{textAlign:"center"}}>
        <Card>
        <CardImg top src={Empresa1} alt="..."/>
        <CardBody>
            <CardTitle style={{fontWeight:600, marginBottom:10, fontSize:18, color:"#D01E25"}}>Maple Bear</CardTitle>
            <CardText style={{fontWeight:400,}}>With more than 550 K-12 schools, Maple Bear is present in more than 30 countries with a pioneering model in bilingualism and ranked among the best in the world.</CardText>
            <br />
            <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#D01E25", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://maplebear.com.br/"
                  target="_Blank"
                  outline
                >
                  Visit website
                </Button>
        </CardBody>
        </Card>        
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
        <CardImg top src={Empresa2} alt="..."/>
        <CardBody>
            <CardTitle style={{fontWeight:600, marginBottom:10, fontSize:18, color:"#F58220"}}>Luminova</CardTitle>
            <CardText style={{fontWeight:400,}}>Luminova was born with the purpose of democratizing quality education. A widely accessible school, with active methodologies, lots of technology and English classes every day.</CardText>
            <br /><br />
            <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#F58220", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://escolaluminova.com.br/"
                  target="_Blank"
                  outline
                >
                  Visit website
                </Button>
        </CardBody>
        </Card>        
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
        <CardImg top src={Empresa3} alt="..."/>
        <CardBody>
            <CardTitle style={{fontWeight:600, marginBottom:10, fontSize:18, color:"#1768B4"}}>Sphere International School</CardTitle>
            <CardText style={{fontWeight:400,}}>From Brazil to the world, Sphere is certified by IB (International Baccalaureate) and offers international and bilingual education, integration of national and international curricula and prepares young leaders to work in Brazil and abroad. </CardText>
            <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#1768B4", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://sphereinternationalschool.com.br/"
                  target="_Blank"
                  outline
                >
                 Visit website
                </Button>
        </CardBody>
        </Card>        
        </Col>
      </Row>  
    </div>         
  </Container>
</div>



<hr style={{borderWidth:2, marginBottom:30}} />

<div style={{marginBottom:30}}>
    <Container>
    <div className="title" style={{fontSize:38, textAlign:"center", lineHeight:1.3, paddingTop:0}}>
      <span style={{fontWeight:550, color:"#013C4E"}}>Premium Schools</span>
    </div>
    <div style={{fontSize:18, textAlign:"center", lineHeight:1.3,}}>
      <span style={{fontWeight:400,}}>A pedagogical proposal that prioritizes thinking in two languages.</span>
    </div>
    </Container>  
</div>

<div>
  <Container>
    <div>     
      <Row>
        <Col sm style={{textAlign:"center"}}>
        

          <Card>
        <CardImg top src={Empresa9} alt="..." />
        <CardBody>
            <CardTitle style={{fontWeight:600, marginBottom:10, fontSize:18, color:"#52BA45"}}>Escola Carolina Patrício</CardTitle>
            <CardText style={{fontWeight:400,}}>A current curriculum proposal, with integral and bilingual learning. This is how the Escola Carolina Patrício follows its essence and, at the same time, reaffirms the position it has occupied since its foundation. The pedagogical proposal is based on the construction of critical thought, providing digital fluency and promoting the development of social-emotional skills.<br /> &nbsp;</CardText>
            <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#52BA45", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://www.escolacarolinapatricio.com.br/"
                  target="_Blank"
                  outline
                >
                  Visit website
                </Button>
        </CardBody>
        </Card>         
        </Col>
        <Col sm style={{textAlign:"center"}}>

        <Card>
        <CardImg top src={Empresa7} alt="..."/>
        <CardBody>
            <CardTitle style={{fontWeight:600, marginBottom:10, fontSize:18, color:"#F58220"}}>Pueri Domus</CardTitle>
            <CardText style={{fontWeight:400,}}>Escola Bilíngue Pueri Domus prepares the student to live in a changing world. Learning proposals are active, up-to-date, and seek to develop skills such as critical thinking, creativity, and collaboration. To make the student a global citizen, the school offers International Baccalaureate (IB) training, which opens the doors of universities in Brazil and abroad. </CardText>
            <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#F58220", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://pueridomus.com.br/"
                  target="_Blank"
                  outline
                >
                  Visit website
                </Button>
        </CardBody>
        </Card>  


       
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
        <CardImg top src={Empresa8} alt="..."/>
        <CardBody>
            <CardTitle style={{fontWeight:600, marginBottom:10, fontSize:18, color:"#1768B4"}}>Pueri Bilíngue Candanguinho </CardTitle>
            <CardText style={{fontWeight:400,}}>At Pueri Bilíngue Candanguinho, students find a bilingual, integral and innovative school, and have access to tools and repertoire that enable them to solve current and future problems. <br />&nbsp;</CardText>
            <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#1768B4", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://escolapueribilingue.com.br/candanguinho/"
                  target="_Blank"
                  outline
                >
                  Visit website
                </Button>
        </CardBody>
        </Card>        
        </Col>
      </Row>  
    </div>         
  </Container>
</div>  



<hr style={{borderWidth:2, marginBottom:30}} />

<div style={{marginBottom:30}}>
    <Container>
    <div className="title" style={{fontSize:38, textAlign:"center", lineHeight:1.3, paddingTop:0}}>
      <span style={{fontWeight:550, color:"#013C4E"}}>High Performance Schools</span>
    </div>
    <div style={{fontSize:18, textAlign:"center", lineHeight:1.3,}}>
      <span style={{fontWeight:400,}}>Tradition in approving in more than 30 schools throughout Brazil.</span>
    </div>
    </Container>  
</div>

<div>
  <Container>
    <div>     
      <Row>
        <Col sm style={{textAlign:"center"}}>
        <Card>
        <CardImg top src={Empresa4} alt="..."/>
        <CardBody>
            <CardTitle style={{fontWeight:600, marginBottom:10, fontSize:18, color:"#4ABAD0"}}>Escolas SEB</CardTitle>
            <CardText style={{fontWeight:400,}}>With a legacy of more than 60 years, Escolas SEB are contemporary and technological, focused on developing autonomy and the most different skills of students to work in an increasingly interactive, digital and globalized world.</CardText>
            <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#4ABAD0", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://www.escolaseb.com.br/"
                  target="_Blank"
                  outline
                >
                  Visit website
                </Button>
        </CardBody>
        </Card>        
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
        <CardImg top src={Empresa5} alt="..."/>
        <CardBody>
            <CardTitle style={{fontWeight:600, marginBottom:10, fontSize:18, color:"#FFC426"}}>Colégios AZ</CardTitle>
            <CardText style={{fontWeight:400,}}>The successful experience of the pre-university course that has become a reference is now called Colégios AZ. It offers very high-level high school, with technological and avant-garde methodology aimed at complete individual education and focused on digital transformation.</CardText>
            <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#FFC426", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://www.deaaz.com.br/"
                  target="_Blank"
                  outline
                >
                 Visit website
                </Button>
        </CardBody>
        </Card>        
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
        <CardImg top src={Empresa6} alt="..." />
        <CardBody>
            <CardTitle style={{fontWeight:600, marginBottom:10, fontSize:18, color:"#1768B4"}}>Colégio Visão</CardTitle>
            <CardText style={{fontWeight:400,}}>A high school and pre-university reference in approvals for families in Goiânia since 1995. The school and all its spaces are designed according to the learning processes and their needs. Together with the curriculum, we encourage research and experimentation so that the learning journey is complete and approving. </CardText>
            <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#1768B4", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://www.visaoportal.com.br/"
                  target="_Blank"
                  outline
                >
                 Visit website
                </Button>
        </CardBody>
        </Card>        
        </Col>
        <Col sm style={{textAlign:"center"}}>
        <Card>
        <CardImg top src={Empresa14} alt="..." />
        <CardBody>
            <CardTitle style={{fontWeight:600, marginBottom:10, fontSize:18, color:"#1768B4"}}>Colégio Santa Maria</CardTitle>
            <CardText style={{fontWeight:400,}}>Colégio Santa Maria is in tune with the times and focused on the future, while preserving its roots and the wealth of experience gained throughout its history.

For this reason, our commitment is reflected in the results of excellence and high academic performance achieved in more than 60 years dedicated to education in Brazil. </CardText>

<Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#1768B4", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://www.colegiosantamaria.com.br/pt/"
                  target="_Blank"
                  outline
                >
                  Visit website
                </Button>
           
        </CardBody>
        </Card>        
        </Col>
      </Row>  
    </div>         
  </Container>
</div>



 

<hr style={{borderWidth:2, marginBottom:30}} />

<div style={{marginBottom:30}}>
    <Container>
    <div className="title" style={{fontSize:38, textAlign:"center", lineHeight:1.3, paddingTop:0}}>
      <span style={{fontWeight:550, color:"#013C4E"}}>Escolas Concept</span>
    </div>
    <div style={{fontSize:18, textAlign:"center", lineHeight:1.3,}}>
      <span style={{fontWeight:400,}}>Created with the objective of breaking the paradigms of education in Brazil.</span>
    </div>
    </Container>  
</div>

<div>
  <Container>
    <div>     
      <Row>
        <Col sm style={{textAlign:"center"}}>
        

          <Card>
        <CardImg top src={Empresa10} alt="..." />
        <CardBody>            
            <CardText style={{fontWeight:400,}}>International, bilingual and innovative. Inspired by Silicon Valley, Escolas Concept proposes a project-based learning environment, inspiring creative practices and student development in four pillars: entrepreneurship, sustainability, collaboration and digital fluency.</CardText>
            <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#42C0C1", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://www.escolaconcept.com.br/"
                  target="_Blank"
                  outline
                >
                 Visit website
                </Button>
        </CardBody>
        </Card>         
        </Col>
        


      </Row>  
    </div>         
  </Container>
</div>  



<hr style={{borderWidth:2, marginBottom:30}} />

<div style={{marginBottom:30}}>
    <Container>
    <div className="title" style={{fontSize:38, textAlign:"center", lineHeight:1.3, paddingTop:0}}>
      <span style={{fontWeight:550, color:"#013C4E"}}>Higher Education</span>
    </div>
    <div style={{fontSize:18, textAlign:"center", lineHeight:1.3,}}>
      <span style={{fontWeight:400,}}>Excellence in Undergraduate and Graduate Studies, in person or remotely.</span>
    </div>
    </Container>  
</div>

<div>
  <Container>
    <div>     
      <Row>
        <Col sm style={{textAlign:"center"}}>
        

          <Card>
        <CardImg top src={Empresa11} alt="..." />
        <CardBody>
            <CardTitle style={{fontWeight:600, marginBottom:10, fontSize:18, color:"#D01E25"}}>EPD</CardTitle>
            <CardText style={{fontWeight:400,}}>With more than 20 years of tradition and maximum grade in MEC, Escola Paulista de Direito (EPD) is a reference in undergraduate, graduate and extension courses in Law and related areas, in face-to-face and Remote Learning (EaD).</CardText>
            <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#D01E25", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://www.epd.edu.br/"
                  target="_Blank"
                  outline
                >
                  Visit website
                </Button>
        </CardBody>
        </Card>         
        </Col>
        
        <Col sm style={{textAlign:"center"}}>
        

        <Card>
      <CardImg top src={Empresa12} alt="..." />
      <CardBody>
          <CardTitle style={{fontWeight:600, marginBottom:10, fontSize:18, color:"#42C0C1"}}>UNIDOMBOSCO </CardTitle>
          <CardText style={{fontWeight:400,}}>A University Center reference in quality higher education, focused on the integral formation of critical, ethical and responsible individuals, through innovative education and sustainable management.</CardText>
          <Button
                className="btn-round"
                style={{color:"#fff", width:"100%", backgroundColor:"#42C0C1", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                href="https://www.unidombosco.edu.br/"
                target="_Blank"
                outline
              >
                Visit website
              </Button>
      </CardBody>
      </Card>         
      </Col>

      </Row>  
    </div>         
  </Container>
</div>  




<hr style={{borderWidth:2, marginBottom:30}} />

<div style={{marginBottom:30}}>
    <Container>
    <div className="title" style={{fontSize:38, textAlign:"center", lineHeight:1.3, paddingTop:0}}>
      <span style={{fontWeight:550, color:"#013C4E"}}>Conexia Educação</span>
    </div>
    <div style={{fontSize:18, textAlign:"center", lineHeight:1.3,}}>
      <span style={{fontWeight:400,}}>Reference in innovative educational solutions.</span>
    </div>
    </Container>  
</div>

<div>
  <Container>
    <div>     
      <Row>
        <Col sm style={{textAlign:"center"}}>
        

          <Card>
        <CardImg top src={Empresa13} alt="..." />
        <CardBody>     
            <CardText style={{fontWeight:400,}}>Conexia Educação is an Edtech that uses the traditional and the digital as allies to enhance learning and empower students and teachers in search of a more collaborative and data-based work. <br /><br />

            Present in more than 30 countries, Conexia is responsible for the educational solutions of Maple Bear, SEB Group and more than 400 schools worldwide. <br /><br />

            Among the platforms of Conexia Educação are Plataforma AZ, High Five, My Life and Conexia Lex.
</CardText>
            <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#42C0C1", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  href="https://conexia.com.br/"
                  target="_Blank"
                  outline
                >
                  Visit website
                </Button>
        </CardBody>
        </Card>         
        </Col>
        


      </Row>  
    </div>         
  </Container>


</div>  


    </>
  );
}

export default MainEng;
