
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
import { Link, animateScroll as scroll } from "react-scroll";
import Logo from "../../assets/img/logoSEB.png";
// reactstrap components
import {
  Button,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbar2() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-white");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };


  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container >
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"         
          >

<NavLink
                href="/"
                style={{textTransform:"none", fontSize:13, color:"#333", fontWeight:400, cursor:"pointer"}}
              >
                <img src={Logo} width="80" />
              </NavLink>
           
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>            
            <NavItem>
            <NavLink
                href="/#quemsomos"
                style={{textTransform:"none", fontSize:13,  color:"#333", fontWeight:400, cursor:"pointer"}}
              >
                  Quem somos            
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
                href="/#marcas"
                style={{textTransform:"none", fontSize:13,  color:"#333", fontWeight:400, cursor:"pointer"}}
              >
                  Nossas marcas            
              </NavLink>
            </NavItem>
            <NavItem>     
              <NavLink
                href="https://www.institutoseb.org.br/"
                target="_blank"
                style={{textTransform:"none", fontSize:13,  color:"#333", fontWeight:400, cursor:"pointer"}}
              >
                Instituto
              </NavLink>
            </NavItem>
            
            <NavItem>
            <NavLink
                href="/conduta"
                style={{textTransform:"none", fontSize:13,  color:"#333", fontWeight:400, cursor:"pointer"}}
              >
                  Conduta e Ética             
              </NavLink>
            </NavItem>
            <NavItem>
            <UncontrolledDropdown style={{borderColor:"transparent", margin:0, padding:0}}>
              <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                data-toggle="dropdown"
                id="dropdownMenuButton2"
                style={{backgroundColor:"transparent", fontSize:13, borderColor:"transparent", color:"#333", textTransform:"none", padding:8}}
              >
                Trabalhe Conosco
              </DropdownToggle>
              <DropdownMenu  style={{borderColor:"transparent",}}>
                <DropdownItem style={{textTransform:"none",}} href="https://gruposeb.gupy.io" target="_Blank">
                 Vagas
                </DropdownItem>
                <DropdownItem href="https://jovenstalentos.sebsa.com.br" target="_Blank">
                Programa Jovens Talentos
                </DropdownItem>  
                <DropdownItem href="https://programadeestagio.sebsa.com.br" target="_Blank">
                Programa de Estágio
                </DropdownItem>              
              </DropdownMenu>
            </UncontrolledDropdown>
            </NavItem>
            <NavItem>
  

            <UncontrolledDropdown style={{borderColor:"transparent", margin:0, padding:0}}>
              <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                data-toggle="dropdown"
                id="dropdownMenuButton"
                style={{backgroundColor:"transparent", fontSize:13, borderColor:"transparent", color:"#333", textTransform:"none", padding:8}}
              >
                Área do Colaborador
              </DropdownToggle>
              <DropdownMenu  style={{borderColor:"transparent",}}>
                <DropdownItem style={{textTransform:"none",}} href="https://outlook.office365.com"  target="_Blank">
                  E-mail Coporativo
                </DropdownItem>
                <DropdownItem href="https://rh.sebsa.com.br/recibopagto" target="_Blank">
                 Holerite
                </DropdownItem>                
              </DropdownMenu>
            </UncontrolledDropdown>

       
            </NavItem> 
            <NavItem>        
            <NavLink
                href="https://www.linkedin.com/company/gruposeb/"
                target="_blank"
              >
                <i className="fa fa-linkedin" />
              </NavLink>
            </NavItem>   
            <NavItem>        
            <NavLink
                href="https://www.youtube.com/channel/UC8uUQ0T2dvZXTA9SCypMBvQ/videos"
                target="_blank"
              >
                <i className="fa fa-youtube" />
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar2;
