import React from "react";
// plugin that creates slider
import Logos from "../../assets/img/logos.png";
import imgAcelera from "../../assets/img/img_acelera.png";
import Empresa1 from "../../assets/img/empresa_01.png";
import Empresa2 from "../../assets/img/empresa_02.png";
import Empresa3 from "../../assets/img/empresa_03.png";
import Empresa4 from "../../assets/img/empresa_04.png";
import Empresa5 from "../../assets/img/empresa_05.png";
import Empresa6 from "../../assets/img/empresa_06.png";
import Empresa7 from "../../assets/img/empresa_07.png";
import Empresa8 from "../../assets/img/empresa_08.png";
import Empresa9 from "../../assets/img/empresa_09.png";
import Empresa10 from "../../assets/img/empresa_10.png";
import Empresa11 from "../../assets/img/empresa_11.png";
import Empresa12 from "../../assets/img/empresa_12.png";
import Empresa13 from "../../assets/img/empresa_13.png";
import Empresa14 from "../../assets/img/empresa_14.jpg";


// reactstrap components
import {
  Container,
  Row,
  Col,
  Button, Card, CardImg, CardBody, CardTitle, CardText
} from "reactstrap";

function Main() {
  
  const html = document.querySelector("html");
  html.setAttribute("lang", "pt-br");

  return (
    <>
      <div id="quemsomos">
        <Container>
          <div style={{ textAlign: "center", marginTop: 50, marginBottom: 50 }}>
            <img src={Logos} width="90%" />
          </div>
        </Container>

        <hr style={{ borderWidth: 2 }} />

        <Container>
          <div>
            <Row>
              <Col sm>
                <div style={{ fontSize: 14, fontWeight: 400, lineHeight: 2, }}>
                  Estamos focados em propostas pedagógicas disruptivas e inovadoras, que desenvolvam diferentes habilidades em nossos estudantes, preparando-os para os desafios do futuro, em qualquer lugar do mundo!<br /><br />

                  Hoje, <span style={{ fontWeight: 600 }}>somos um time de mais de 6 mil agentes de transformação que participam ativamente da vida de mais de 350 mil alunos em 30 países</span>, seja atuando nas nossas escolas próprias e franquias ou desenvolvendo soluções inovadoras na Conexia, nossa edtech.<br /><br />

                  <span style={{ fontWeight: 600 }}>Nosso propósito é transformar pessoas por meio da educação.</span> Porque acreditamos que pessoas curiosas, com senso crítico, criatividade e autonomia, ancoradas em valores humanos e na aprendizagem contínua, são capazes de transformar o mundo.<br /><br />

                  Por isso, somos arrojados e crescemos em ritmo acelerado, investindo fortemente na digitalização, internacionalização e na premiunização ou diferenciação de todas nossas operações, voltadas para diferentes segmentos de ensino e para diferentes classes socioeconômicas.<br /><br />
                </div>
              </Col>

              <Col sm>
                <div style={{ fontSize: 14, fontWeight: 400, lineHeight: 2, marginBottom: 20, }}>
                  Temos muito orgulho da nossa origem. Nascemos do empreendedorismo de nossos fundadores, um casal de educadores visionários que sempre colocaram a qualidade do ensino em primeiro lugar e enxergaram a escola como potencial agente de integração de diferentes vivências e culturas. Não é à toa que, em nossos mais de 50 anos, os alunos estão no centro de todas as nossas decisões, e investimos fortemente em tecnologia e no intraempreendedorismo como aliados da melhor experiência aos estudantes e famílias.
                </div>
                <img src={imgAcelera} width="90%" />
              </Col>

            </Row>
          </div>
        </Container>
      </div>

      <div style={{ backgroundColor: "#02CFAA", paddingTop: 10, paddingBottom: 50, marginTop: -20 }}>
        <Container>
          <div style={{ marginTop: 30 }}>

            <div style={{ marginBottom: 30 }}>
              <Container>
                <div className="title" style={{ fontSize: 38, textAlign: "center", lineHeight: 1.3, paddingTop: 0 }}>
                  <span style={{ fontWeight: 550, color: "#fff" }}>Vem transformar vidas<br /> e mudar o mundo com a gente!</span>
                </div>
                <div style={{ fontSize: 18, textAlign: "center", lineHeight: 1.3, color: "#fff" }}>
                  <span style={{ fontWeight: 400, }}>Quer fazer parte do nosso grupo e ter infinitas oportunidades para aprender e crescer com a gente? Confira nossas vagas:</span>
                </div>
              </Container>
            </div>

            <Row>
              <Col sm>
                <div style={{ fontSize: 20, fontWeight: 600, color: "#fff", textAlign: "center", marginBottom: 20 }}>
                  <i className="nc-icon nc-bulb-63" style={{ fontSize: 50 }} /><br />
                  Vagas em todo o grupo
                </div>

                <div style={{ textAlign: "center", marginTop: 20, }}>
                  <Button
                    className="btn-round"
                    style={{ color: "#fff", width: "100%", borderColor: "#fff", fontSize: 14, textTransform: "none" }}
                    href="https://gruposeb.gupy.io/"
                    target="_Blank"
                    outline
                  >
                    Clique aqui
                  </Button>
                  <br />&nbsp;
                </div>
              </Col>

              <Col sm>
                <div style={{ fontSize: 20, fontWeight: 600, color: "#fff", textAlign: "center", marginBottom: 20 }}>
                  <i className="nc-icon nc-spaceship" style={{ fontSize: 50 }} /><br />
                  Jovens Talentos
                </div>


                <div style={{ textAlign: "center", marginTop: 20, }}>
                  <Button
                    className="btn-round"
                    style={{ color: "#fff", width: "100%", borderColor: "#fff", fontSize: 14, textTransform: "none" }}
                    href="https://jovenstalentos.sebsa.com.br"
                    target="_Blank"
                    outline
                  >
                    Clique aqui
                  </Button>
                  <br />&nbsp;
                </div>
              </Col>

              <Col sm>
                <div style={{ fontSize: 20, fontWeight: 600, color: "#fff", textAlign: "center", marginBottom: 20 }}>
                  <i className="nc-icon nc-world-2" style={{ fontSize: 50 }} /><br />
                  Programa de estágio
                </div>

                <div style={{ textAlign: "center", marginTop: 20, }}>
                  <Button
                    className="btn-round"
                    style={{ color: "#fff", width: "100%", borderColor: "#fff", fontSize: 14, textTransform: "none" }}
                    href="https://programadeestagio.sebsa.com.br"
                    target="_Blank"
                    outline
                  >
                    Clique aqui
                  </Button>
                  <br />&nbsp;
                </div>
              </Col>

            </Row>
          </div>
        </Container>
      </div>

      <div id="marcas">
        <Container>
          <div className="title" style={{ fontSize: 38, textAlign: "center", lineHeight: 1.3, paddingTop: 20 }}>
            <span style={{ fontWeight: 550, color: "#013C4E" }}>Conheça nossas marcas!</span>
          </div>
        </Container>
      </div>

      <hr style={{ borderWidth: 2, marginBottom: 30 }} />

      <div style={{ marginBottom: 30 }}>
        <Container>
          <div className="title" style={{ fontSize: 38, textAlign: "center", lineHeight: 1.3, paddingTop: 0 }}>
            <span style={{ fontWeight: 550, color: "#013C4E" }}>Franquias</span>
          </div>
          <div style={{ fontSize: 18, textAlign: "center", lineHeight: 1.3, }}>
            <span style={{ fontWeight: 400, }}>Mais de 550 instituições de ensino bilíngue em 30 países.</span>
          </div>
        </Container>
      </div>

      <div>
        <Container>
          <div>
            <Row>
              <Col sm style={{ textAlign: "center" }}>
                <Card>
                  <CardImg top src={Empresa1} alt="..." />
                  <CardBody>
                    <CardTitle style={{ fontWeight: 600, marginBottom: 10, fontSize: 18, color: "#D01E25" }}>Maple Bear</CardTitle>
                    <CardText style={{ fontWeight: 400, }}>Com mais de 550 escolas de Ensino Infantil, Fundamental e Médio, a Maple Bear está presente em mais de 30 países com um modelo pioneiro em bilinguismo e classificado entre os melhores do mundo.</CardText>
                    <br />
                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#D01E25", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://maplebear.com.br/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>
                  </CardBody>
                </Card>
              </Col>

              <Col sm style={{ textAlign: "center" }}>
                <Card>
                  <CardImg top src={Empresa2} alt="..." />
                  <CardBody>
                    <CardTitle style={{ fontWeight: 600, marginBottom: 10, fontSize: 18, color: "#F58220" }}>Luminova</CardTitle>
                    <CardText style={{ fontWeight: 400, }}>A Luminova nasceu com o propósito de democratizar o ensino de qualidade. Uma escola amplamente acessível, com metodologias ativas, muita tecnologia e aulas de inglês todos os dias.</CardText>
                    <br /><br />
                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#F58220", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://escolaluminova.com.br/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>
                  </CardBody>
                </Card>
              </Col>

              <Col sm style={{ textAlign: "center" }}>
                <Card>
                  <CardImg top src={Empresa3} alt="..." />
                  <CardBody>
                    <CardTitle style={{ fontWeight: 600, marginBottom: 10, fontSize: 18, color: "#1768B4" }}>Sphere International School</CardTitle>
                    <CardText style={{ fontWeight: 400, }}>Do Brasil para o mundo, a Sphere é certificada pelo IB (International Baccalaureate) e oferece ensino internacional e bilíngue, integração dos currículos nacional e internacional e prepara jovens lideranças para atuar no Brasil e no exterior. </CardText>
                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#1768B4", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://sphereinternationalschool.com.br/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </div>
        </Container>
      </div>

      <hr style={{ borderWidth: 2, marginBottom: 30 }} />

      <div style={{ marginBottom: 30 }}>
        <Container>
          <div className="title" style={{ fontSize: 38, textAlign: "center", lineHeight: 1.3, paddingTop: 0 }}>
            <span style={{ fontWeight: 550, color: "#013C4E" }}>Escolas Premium</span>
          </div>
          <div style={{ fontSize: 18, textAlign: "center", lineHeight: 1.3, }}>
            <span style={{ fontWeight: 400, }}>Uma proposta pedagógica que prioriza o pensamento em dois idiomas.</span>
          </div>
        </Container>
      </div>

      <div>
        <Container>
          <div>
            <Row>
              <Col md={5} style={{ margin: '0 auto', textAlign:"center"}}>
                <Card>
                  <CardImg top src={Empresa9} alt="..." />
                  <CardBody>
                    <CardTitle style={{ fontWeight: 600, marginBottom: 10, fontSize: 18, color: "#52BA45" }}>Escola Carolina Patrício </CardTitle>
                    <CardText style={{ fontWeight: 400, }}>Uma proposta curricular atual, com aprendizagem integral e bilíngue. É assim que a Escola Carolina Patrício segue sua essência e, ao mesmo tempo, reafirma a posição que ocupa desde sua fundação. A proposta pedagógica é fundamentada na construção do pensamento crítico, proporcionando ﬂuência digital e promovendo o desenvolvimento de habilidades socioemocionais.<br /> &nbsp;</CardText>
                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#52BA45", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://www.escolacarolinapatricio.com.br/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>
                  </CardBody>
                </Card>
              </Col>

              <Col md={5} style={{ margin: '0 auto', textAlign:"center"}}>
                <Card>
                  <CardImg top src={Empresa7} alt="..." />
                  <CardBody>
                    <CardTitle style={{ fontWeight: 600, marginBottom: 10, fontSize: 18, color: "#F58220" }}>Pueri Domus</CardTitle>
                    <CardText style={{ fontWeight: 400, }}>A Escola Bilíngue Pueri Domus prepara o aluno para viver em um mundo em transformação. As propostas de aprendizagem são ativas, atualizadas e buscam desenvolver habilidades como o pensamento crítico, a criatividade e a colaboração. Para tornar o aluno um cidadão global, a escola oferece formação Internacional Baccalaureate (IB), que abre portas de universidades no Brasil e no exterior. </CardText>
                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#F58220", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://pueridomus.com.br/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </div>
        </Container>
      </div>

      <hr style={{ borderWidth: 2, marginBottom: 30 }} />

      <div style={{ marginBottom: 30 }}>
        <Container>
          <div className="title" style={{ fontSize: 38, textAlign: "center", lineHeight: 1.3, paddingTop: 0 }}>
            <span style={{ fontWeight: 550, color: "#013C4E" }}>Escolas de Alta Performance</span>
          </div>
          <div style={{ fontSize: 18, textAlign: "center", lineHeight: 1.3, }}>
            <span style={{ fontWeight: 400, }}>Tradição em aprovar em mais de 30 escolas pelo Brasil.</span>
          </div>
        </Container>
      </div>

      <div>
        <Container>
          <div>
            <Row>
              <Col sm style={{ textAlign: "center" }}>
                <Card>
                  <CardImg top src={Empresa4} alt="..." />
                  <CardBody>
                    <CardTitle style={{ fontWeight: 600, marginBottom: 10, fontSize: 18, color: "#4ABAD0" }}>Escolas SEB</CardTitle>
                    <CardText style={{ fontWeight: 400, }}>Com um legado de mais de 60 anos, as escolas SEB são contemporâneas e tecnológicas, focadas em desenvolver autonomia e as mais diferentes habilidades dos alunos para atuarem em um mundo cada dia mais interativo, digital e globalizado.</CardText>
                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#4ABAD0", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://www.escolaseb.com.br/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>
                  </CardBody>
                </Card>
              </Col>

              <Col sm style={{ textAlign: "center" }}>
                <Card>
                  <CardImg top src={Empresa5} alt="..." />
                  <CardBody>
                    <CardTitle style={{ fontWeight: 600, marginBottom: 10, fontSize: 18, color: "#FFC426" }}>Colégios AZ</CardTitle>
                    <CardText style={{ fontWeight: 400, }}>A experiência bem-sucedida do curso pré-vestibular que se tornou referência, agora se chama Escola AZ. Ela oferece ensino médio de altíssimo nível, com metodologia tecnológica e de vanguarda visando a educação individual completa e voltada à transformação digital.</CardText>
                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#FFC426", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://www.deaaz.com.br/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>
                  </CardBody>
                </Card>
              </Col>

              <Col sm style={{ textAlign: "center" }}>
                <Card>
                  <CardImg top src={Empresa6} alt="..." />
                  <CardBody>
                    <CardTitle style={{ fontWeight: 600, marginBottom: 10, fontSize: 18, color: "#1768B4" }}>Colégio Visão</CardTitle>
                    <CardText style={{ fontWeight: 400, }}>Ensino médio e pré-vestibular referência em aprovação para as famílias de Goiânia desde 1995. O colégio e todos os seus espaços são pensados de acordo com os processos de aprendizagem e suas necessidades. Juntamente com a grade curricular, estimulamos a pesquisa e a experimentação para que a jornada de aprendizagem seja completa e aprovadora.  </CardText>
                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#1768B4", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://www.visaoportal.com.br/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>
                  </CardBody>
                </Card>
              </Col>

              <Col sm style={{ textAlign: "center" }}>
                <Card>
                  <CardImg top src={Empresa14} alt="..." />
                  <CardBody>
                    <CardTitle style={{ fontWeight: 600, marginBottom: 10, fontSize: 18, color: "#1768B4" }}>Colégio Santa Maria</CardTitle>
                    <CardText style={{ fontWeight: 400, }}>O Colégio Santa Maria está sintonizado com o tempo, direcionado para o futuro, sem deixar de preservar suas raízes e o conjunto de experiências que acumulou ao longo da própria história.<br /><br />

                      Por esse motivo, nossa entrega se reflete nos resultados de excelência e alto desempenho acadêmico conquistados ao longo de mais de 60 anos dedicados à educação no Brasil. </CardText>

                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#1768B4", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://www.colegiosantamaria.com.br/pt/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>

                  </CardBody>
                </Card>
              </Col>

            </Row>
          </div>
        </Container>
      </div>

      <hr style={{ borderWidth: 2, marginBottom: 30 }} />

      <div style={{ marginBottom: 30 }}>
        <Container>
          <div className="title" style={{ fontSize: 38, textAlign: "center", lineHeight: 1.3, paddingTop: 0 }}>
            <span style={{ fontWeight: 550, color: "#013C4E" }}>Escolas Concept</span>
          </div>
          <div style={{ fontSize: 18, textAlign: "center", lineHeight: 1.3, }}>
            <span style={{ fontWeight: 400, }}>Criadas com o objetivo de quebrar os paradigmas da educação no Brasil.</span>
          </div>
        </Container>
      </div>

      <div>
        <Container>
          <div>
            <Row>
              <Col sm style={{ textAlign: "center" }}>
                <Card>
                  <CardImg top src={Empresa10} alt="..." />
                  <CardBody>
                    <CardText style={{ fontWeight: 400, }}>Internacional, bilíngue e inovadora. Inspirada no Vale do Silício, a Escola Concept propõe um ambiente de aprendizado baseado em projetos, inspirando práticas criativas e o desenvolvimento do estudante em quatro pilares: empreendedorismo, sustentabilidade, colaboração e ﬂuência digital. </CardText>
                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#42C0C1", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://www.escolaconcept.com.br/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <hr style={{ borderWidth: 2, marginBottom: 30 }} />

      <div style={{ marginBottom: 30 }}>
        <Container>
          <div className="title" style={{ fontSize: 38, textAlign: "center", lineHeight: 1.3, paddingTop: 0 }}>
            <span style={{ fontWeight: 550, color: "#013C4E" }}>Ensino Superior</span>
          </div>
          <div style={{ fontSize: 18, textAlign: "center", lineHeight: 1.3, }}>
            <span style={{ fontWeight: 400, }}>Excelência na Graduação e Pós Graduação, presencial ou à distância.</span>
          </div>
        </Container>
      </div>

      <div>
        <Container>
          <div>
            <Row>
              <Col md={5} style={{ margin: '0 auto', textAlign:"center"}}>
                <Card>
                  <CardImg top src={Empresa11} alt="..." />
                  <CardBody>
                    <CardTitle style={{ fontWeight: 600, marginBottom: 10, fontSize: 18, color: "#D01E25" }}>EPD</CardTitle>
                    <CardText style={{ fontWeight: 400, }}>Com mais de 20 anos de tradição e nota máxima no MEC, a Escola Paulista de Direito (EPD) é referência em graduação, pós-graduação e cursos de extensão em Direito e áreas correlatas na forma presencial e Ensino à Distância (EaD).</CardText>
                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#D01E25", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://www.epd.edu.br/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>
                  </CardBody>
                </Card>
              </Col>

              <Col md={5} style={{ margin: '0 auto', textAlign:"center"}}>
                <Card>
                  <CardImg top src={Empresa12} alt="..." />
                  <CardBody>
                    <CardTitle style={{ fontWeight: 600, marginBottom: 10, fontSize: 18, color: "#42C0C1" }}>UNIDOMBOSCO </CardTitle>
                    <CardText style={{ fontWeight: 400, }}>Centro Universitário modelo em ensino superior de qualidade, focado na formação integral de indivíduos críticos, éticos e responsáveis, por meio de uma educação inovadora e gestão sustentável.</CardText>
                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#42C0C1", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://www.unidombosco.edu.br/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </div>
        </Container>
      </div>

      <hr style={{ borderWidth: 2, marginBottom: 30 }} />

      <div style={{ marginBottom: 30 }}>
        <Container>
          <div className="title" style={{ fontSize: 38, textAlign: "center", lineHeight: 1.3, paddingTop: 0 }}>
            <span style={{ fontWeight: 550, color: "#013C4E" }}>Conexia Educação</span>
          </div>
          <div style={{ fontSize: 18, textAlign: "center", lineHeight: 1.3, }}>
            <span style={{ fontWeight: 400, }}>Referência em soluções educacionais inovadoras.</span>
          </div>
        </Container>
      </div>

      <div>
        <Container>
          <div>
            <Row>
              <Col sm style={{ textAlign: "center" }}>
                <Card>
                  <CardImg top src={Empresa13} alt="..." />
                  <CardBody>
                    <CardText style={{ fontWeight: 400, }}>A Conexia Educação é uma Edtech que utiliza o tradicional e o digital como aliados para potencializar a aprendizagem e empoderar os alunos e os professores em busca de um trabalho mais colaborativo e baseado em dados.<br /><br />

                      Presente em mais de 30 países, a Conexia é responsável pelas soluções educacionais da Maple Bear, Grupo SEB e de outras mais de 400 escolas em todo o mundo.<br /><br />

                      Entre as plataformas da Conexia Educação estão Plataforma AZ, High Five, My Life e Conexia Lex.
                    </CardText>
                    <Button
                      className="btn-round"
                      style={{ color: "#fff", width: "100%", backgroundColor: "#42C0C1", borderColor: "transparent", fontSize: 14, textTransform: "none" }}
                      href="https://conexia.com.br/"
                      target="_Blank"
                      outline
                    >
                      Visite o site
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Main;
