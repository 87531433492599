import React, { useState } from "react";
import Cookies from 'js-cookie';

// reactstrap components
import { Container, Row, Col, Modal, Button } from "reactstrap";


// core components

function IndexHeaderEng() {
  const Cookie = Cookies.get('closed')
  
  const [show, setShow] = useState(true);


  //if (Cookies.set('closed')) {
    //setShow((s) => !s)
 // }


  const clickCLoseButton = () => {
    Cookies.set('closed', true, { expires: 7})
    setShow((s) => !s)
  };

  const [largeModal, setLargeModal] = React.useState(false);
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/bg_head.jpg").default + ")",
        }}
      >
        
          <Container>
            <Row>            
              <Col sm style={{textAlign:"center", fontSize:16, color:"#fff"}}>
              <div style={{fontSize:50, fontWeight:600, lineHeight:1.1,}}>
              Nice to meet you, <br />
              we are the Grupo SEB.

               </div>
               <div style={{marginTop:30}}>
               <span style={{fontWeight:600}}>A Global Education Group that is always ahead of time. </span>
               </div>
               <div style={{marginTop:30}}>
               <Button
                className="btn-round"
                style={{color:"#fff", borderColor:"#fff", fontSize:14, textTransform:"none"}}
                onClick={() => setLargeModal(true)}
                outline
              >

                
                <i className="nc-icon nc-spaceship"></i> Watch video
              </Button>




 
      <Modal
        isOpen={largeModal}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setLargeModal(false)}
      >


     <iframe width="100.11%" height="440" src="https://www.youtube.com/embed/bKpDkopTbIs" title="Grupo SEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

  

      </Modal>



               </div>
              </Col>
            </Row>
          </Container>
        
    
          <h6 className="category category-absolute">
          <span style={{ fontSize:16, fontWeight:400, textTransform:"none"}} >
        © {new Date().getFullYear()}. Grupo SEB.
        </span>
        </h6>
      
      </div>




    </>
  );
}

export default IndexHeaderEng;
