import React from 'react';
// plugin that creates slider


// reactstrap components
import {
  Col,
  Container, Row, Button, Collapse, UncontrolledCollapse
} from "reactstrap";

function Politicas() {

  return (
    <>
      <div style={{marginTop:150, marginBottom:50}}>
        <Container>
          
          <div style={{textAlign:"left", marginTop:50, marginBottom:20, fontSize:32, fontWeight:600}}>
          Política de Conduta e Ética
          </div>

          <hr style={{borderWidth:2}} />

          <div style={{textAlign:"left" }}>
          <Button id="toggler1" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>OBJETIVO</Button>
          </div>
          
          <UncontrolledCollapse toggler="#toggler1">
          <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
          Orientar a conduta de seus colaboradores, prezando pela ética e idoneidade, explicitando a postura social da empresa em face dos diferentes públicos com os quais interage, bem como respeitar e coibir violações aos diversos aparatos normativos vigentes no país, tais como a Lei n.º 12.846/13, entre outras.
          </div>
          </UncontrolledCollapse>
    
          <hr style={{borderWidth:2}} />

          <div style={{textAlign:"left" }}>
          <Button id="toggler2" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>ABRANGÊNCIA</Button>
          </div>
          
          <UncontrolledCollapse toggler="#toggler2">
          <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
          Todos os colaboradores do Grupo SEB, de forma igual, sem qualquer distinção, independentemente do nível hierárquico, desde que guardem relação comercial com qualquer empresa coligada ao Grupo SEB.
          </div>
          </UncontrolledCollapse>

          <hr style={{borderWidth:2}} />

          <div style={{textAlign:"left" }}>
          <Button id="toggler3" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>CONSIDERAÇÕES INICIAIS</Button>
          </div>
          
          <UncontrolledCollapse toggler="#toggler3">
          <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
          A Diretoria de Gente e Serviços, com apoio das áreas Corporativas, elaborou esta Política visando, por meio da formalização dos padrões e das regras, disseminar o entendimento, promovendo a colaboração dos profissionais do Grupo SEB quanto aos princípios de Conduta e Ética no exercício das atividades profissionais.<br /><br />

Além disso, a presente Política visa propiciar aos colaboradores do Grupo SEB um canal de comunicação e esclarecimento de eventuais violações aos presentes termos, sendo que todos os relatos recebidos serão apurados e, dependendo das circunstâncias, resultarão em ações corretivas, preventivas e/ou disciplinares, sempre que tomarem conhecimento de alguma violação aos presentes termos.<br /><br />

Importante salientar que o Grupo SEB não tolera nenhum tipo de retaliação contra qualquer pessoa que relate uma situação de descumprimento da presente Política ou mesmo auxilie na apuração interna, o que deverá, também, ser reportado.
          </div>
          </UncontrolledCollapse>

          <hr style={{borderWidth:2}} />

          <div style={{textAlign:"left" }}>
          <Button id="toggler4" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>RESPONSABILIDADES</Button>
          </div>
          
          <UncontrolledCollapse toggler="#toggler4">
          <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
          Colaboradores: respeitar as regras e padrões expostos nesta Política, bem como denunciar eventual transgressão das regras dessa Política;<br />

Comissão de Compliance: disseminar o conteúdo, efetuar treinamentos e fiscalizar o cumprimento das regras dispostas nesta Política.
          </div>
          </UncontrolledCollapse>


          <hr style={{borderWidth:2}} />

          <div style={{textAlign:"left" }}>
          <Button id="toggler5" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>ÉTICA E CIDADANIA CORPORATIVA</Button>
          </div>
          
          <UncontrolledCollapse toggler="#toggler5">
          <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
          Respeitamos os direitos humanos e não admitimos nenhuma forma de exploração, discriminação e desrespeito no exercício de nossas atividades, nos ambientes de qualquer uma de nossas unidades;<br /><br />

Somos contrários a qualquer forma de artifício, imposição ou constrangimento em nossas operações e negócios;<br /><br />

Cumprimos as leis, normas e regulamentos dos países e localidades onde atuamos, em todas as instâncias;<br /><br />

Adotamos práticas destinadas à prevenção e ao combate a toda forma de pagamento/recebimento ilícito, ou obtenção de vantagens pessoais, privilégios, fraudes, lavagem de dinheiro e potenciais conflitos de interesses;<br /><br />

<span style={{fontWeight:600}}>Possuímos práticas de governança corporativa, destinadas a garantir:</span><br />
A administração diligente, honesta e que mantenham a boa reputação do Grupo SEB e a satisfação dos seus clientes;<br /><br />

O alinhamento de nossas atividades com os compromissos expressos nesta Política e nas diretrizes de sustentabilidade, gestão de pessoas, performance, sistema de remuneração, gestão de riscos, controles financeiros, auditoria, controles internos e segurança corporativa;<br /><br />

A independência de nossos processos de gestão de riscos, controles internos, auditoria e ouvidoria com o integral suporte e cooperação de seus colaboradores e relacionados com os procedimentos de auditorias internas e externas, sindicâncias internas e em investigações, fornecendo informações completas e precisas, bem como preservando todo o material que possa ser relevante;<br /><br />

Que a sociedade e seu público de relacionamento sejam informados tempestivamente sobre os propósitos, decisões e resultados do Grupo SEB;<br /><br />

Que as declarações, relatórios, comunicados e demonstrativos reflitam com clareza e correção a realidade do Grupo SEB;<br /><br />

Que nossos contratos possam ser facilmente compreendidos e que evidenciem os diretos e deveres das partes, salientando os riscos existentes;<br /><br />

A proteção das informações não públicas (restritas, confidenciais e internas), visando evitar que seu vazamento prejudique o Grupo SEB e seus públicos de relacionamento.
          </div>
          </UncontrolledCollapse>

   
          <hr style={{borderWidth:2}} />

<div style={{textAlign:"left" }}>
<Button id="toggler6" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>CONDUTA COM O PÚBLICO DE RELACIONAMENTO DO GRUPO SEB</Button>
</div>

<UncontrolledCollapse toggler="#toggler6">
<div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
Realizamos operações e negócios em condições de mercado e levamos em consideração, em primeiro lugar, os interesses do Grupo SEB, observando condições estritamente comutativas, negociadas de forma independente, mediante processo transparente, ético e em conformidade com a legislação vigente;<br /><br />

Fornecemos de forma clara e correta informações necessárias para que as partes relacionadas tomem suas decisões;<br /><br />

Adotamos critérios transparentes e que aumentam o grau de imparcialidade na gestão dos conflitos de agenda, visando eliminar perda de direitos e conciliação de interesses das partes relacionadas.
</div>
</UncontrolledCollapse>


<hr style={{borderWidth:2}} />

<div style={{textAlign:"left" }}>
<Button id="toggler7" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>RELACIONAMENTO COM FORNECEDORES E PARCEIROS</Button>
</div>

<UncontrolledCollapse toggler="#toggler7">
<div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
Por características do seu negócio, os fornecedores de materiais didáticos do Grupo SEB são homologados e controlados pela Conexia;<br /><br />

Para os demais insumos e serviços, prevalecem acordos de fornecimento baseados em critérios técnicos, profissionais e éticos, que conduzimos por meio de cotações de preços e licitações, garantindo a melhor relação custo-benefício;<br /><br />

Não interferem, nesse processo, quaisquer aspectos que caracterizem privilégios ou discriminação;<br /><br />

Adotamos critérios justos e transparentes de seleção e contratação de fornecedores via escolhas conscientes norteadas por processos predeterminados, como concorrência ou cotação de preços, para que sejam contratados os melhores fornecedores, garantindo, assim, o melhor retorno para o Grupo SEB em termos de qualidade, custo e alinhamento com os valores sociais do Grupo SEB.<br /><br />

Temos como princípio atuar de forma idônea, permitindo oportunidades iguais a todos os fornecedores e parceiros;<br /><br />

Buscamos desenvolver relacionamentos comerciais de longo prazo, incentivando seus fornecedores a compartilhar nossa filosofia de melhoria contínua;<br /><br />

<span style={{fontWeight:600}}>Não contratamos fornecedores ou prestadores de serviços que:</span><br />
Forneçam produtos ou serviços sem respeitar as normas de meio ambiente, qualidade e segurança;<br />
Utilizem trabalho escravo, forçado ou infantil;<br />
Façam uso de castigos físicos, morais ou monetários para punir seus funcionários;<br />
Violem leis e regulamentações vigentes.<br /><br />

<span style={{fontWeight:600}}>Recebimento de brindes</span><br /><br />

Adotamos a prática da “reciprocidade” na forma de aquisição, em contrapartida ao recebimento de brindes ou presentes para seus colaboradores;<br /><br />

Não recebemos ou oferecemos presentes, favores ou ofertas de entretenimento que possam influenciar qualquer decisão comercial ou que gere alguma forma de obrigação da pessoa que os recebeu;<br /><br />

Em hipótese alguma nossos colaboradores podem receber qualquer importância em dinheiro de clientes, fornecedores ou terceiros a título de gratificação;<br /><br />

Nossos colaboradores podem aceitar ou ofertar (como um ato de cortesia ou com fins institucionais) brindes, presentes e entretenimentos que não custem ou aparentem custar mais de R$ 50,00 (cinquenta reais), tais como: cestas, panetones, chocolates, agendas, cadernos, canetas, lápis ou calendários, ingressos para shows, teatro e cinema, eventos esportivos, almoços e jantares;<br /><br />

<span style={{fontWeight:600}}>Conflitos de interesses</span><br /><br />

Atuamos de forma idônea, permitindo oportunidades iguais a todos os fornecedores e parceiros;<br /><br />

Repudiamos qualquer prática de corrupção, suborno, propina, favorecimento pessoal e demais formas de atos ilícitos ou criminosos em suas operações;<br /><br />

Os nossos colaboradores devem evitar qualquer situação que possa envolver um conflito entre seus interesses pessoais e os da empresa, tanto na esfera financeira quanto na questão de presentes, refeições e/ou entretenimento em contrapartida por qualquer relacionado comercial interno ligado ao Grupo SEB.
</div>
</UncontrolledCollapse>
        


<hr style={{borderWidth:2}} />

<div style={{textAlign:"left" }}>
<Button id="toggler8" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>RELACIONAMENTO COM COLABORADORES</Button>
</div>

<UncontrolledCollapse toggler="#toggler8">
<div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
Regras de Conduta Ética do Grupo SEB<br /><br />

Repudiamos qualquer forma de discriminação ou assédio;<br /><br />

Atuamos com políticas e práticas internas destinadas a prevenir e combater as formas degradantes de trabalho (infantil, forçado, escravo);<br /><br />

Respeitamos os direitos políticos e trabalhistas dos colaboradores, incluindo o direito à negociação coletiva e à associação partidária e sindical;<br /><br />

Somos contra qualquer forma de intimidação ou assédio sexual, moral, religioso, político ou organizacional;<br /><br />

Nossos processos de gestão de pessoas (contratação, transferência, promoção, aumentos salariais por méritos, sanções disciplinares, desligamento) são realizados com respeito, transparência, imparcialidade e com base nos preceitos da meritocracia;
<br /><br />

Os colaboradores do Grupo SEB devem:<br /><br />

Desempenhar as suas atividades em consonância com esta Política;<br /><br />


Seguir e respeitar as Políticas e Normas internas do SEB, estimulando e orientando os seus colegas no mesmo sentido;<br /><br />


Manter uma atitude profissional positiva, digna, leal, honesta, de respeito mútuo, confiança e colaboração com os demais colegas de trabalho;<br /><br />


Atuar com imparcialidade, objetividade, honestidade, respeito, transparência, lealdade e cortesia na relação com administradores, empregados, fornecedores, clientes, acionistas e investidores;<br /><br />


Atuar em estrita conformidade com todas as leis aplicáveis, inclusive as leis antissuborno e anticorrupção, que se aplicam às operações nos estados de atuação do Grupo SEB; Ter atitude de dono, de modo a encarar todas as atividades com atitude de quem é responsável pelo seu sucesso. Realizá-las com senso de urgência, cumprindo os prazos e orçamentos acordados, com transparência, ética e atendendo aos requerimentos de qualidade e sustentabilidade;<br /><br />

Buscar constantemente excelência na execução, de modo a atingir o mais alto grau de performance, aperfeiçoando as práticas do dia a dia e prezando pela qualidade plena no resultado de cada trabalho;<br /><br />


Preservar o patrimônio do Grupo SEB, incluindo sua imagem e reputação, instalações, equipamentos e materiais, utilizando-os apenas para os fins a que se destinam, prezando pela prudência, normas de segurança de informação, especificamente a Política de Segurança da Informação, de uso, preservando a integridade;<br /><br />


Ser diligente e responsável no trato com as autoridades, clientes, fornecedores, membros das comunidades e todos os demais indivíduos, empresas e organizações com que o Grupo SEB se relaciona no exercício de suas atividades regulares, buscando sempre preservar a boa reputação, imagem e relações da empresa;<br /><br />


Evitar situações em que possam ocorrer conflitos de interesses do SEB, abstendose de qualquer ato que venha caracterizar tal conflito, com a comunicação imediata do fato ao superior hierárquico;<br /><br />


Recusar relações comerciais com empresas e indivíduos que não observem padrões éticos, legais, de saúde/segurança e de direitos humanos e sociais compatíveis com os do SEB;<br /><br />


Executar as práticas antissuborno e anticorrupção (Lei 12.846/2013) adotadas pelo Grupo;<br /><br />


Proteger qualquer informação ou estratégia confidencial e sensível do Grupo SEB a que tiver acesso, mesmo na condição de terceiro, inclusive se deixar de ter vínculo com o Grupo, assim como manter confidencialidade sobre as informações privilegiadas;
<br /><br />

Guardar documentos confidenciais em arquivos protegidos e destruí-los adequadamente antes do descarte;<br /><br />


Agir com responsabilidade social e com respeito à dignidade humana;<br /><br />


Executar as atividades diárias respeitando os aspectos de segurança e saúde definidos pela empresa;<br /><br />

Atuar nas redes sociais respeitando os valores do SEB e esta Política, visando evitar prejuízos nas atividades profissionais desenvolvidas, assim como perda de produtividade;<br /><br />


Utilizar o aparelho celular durante a jornada de trabalho com bom senso, visando evitar prejuízos nas atividades profissionais desenvolvidas, assim como perda de produtividade;<br /><br />


Ajudar os colegas a realizar um bom trabalho e orientar com paciência e interesse aqueles que o procuram;<br /><br />


Evitar a indução de alguém ao erro ou causar-lhe prejuízo;<br /><br />


Evitar a adoção de condutas ofensivas, sejam elas verbais, físicas ou gestuais;<br /><br />


Evitar a reprodução de boatos e maledicências;<br /><br />


Ao errar, não ocultar o fato, agir de forma transparente e verdadeira, consoante a Missão e os Valores do Grupo SEB, esforçando-se para mitigar suas consequências e aprender com elas;<br /><br />


Adotar os preceitos de consumo consciente: não desperdiçar água, energia, papel, objetos de plástico, materiais de escritório etc.;<br /><br />

Zelar pela aparência pessoal, vestindo-se adequadamente à função desempenhada.<br /><br />

Preservar a imagem, os signos, elementos distintivos, marcas, logomarcas e demais direitos de propriedade intelectual de propriedade do SEB quando da utilização de mídias e redes sociais de qualquer espécie.<br /><br />

Evitar que seja verificado um conflito de interesses quando da existência de parentesco ou relacionamento afetivo entre nossos colaboradores. Nesse sentido, estabelecemos certas regras que devem ser observadas quando houver uma relação de parentesco ou um relacionamento afetivo. São considerados parentes: filhos, enteados, netos e bisnetos; cônjuges/companheiros, sogros, cunhados, genros e noras; pais, padrasto, madrasta, irmãos, sobrinhos e sobrinhos-netos; avós, tios e primos; bisavós e tios-avós. É permitida uma relação de parentesco ou um relacionamento afetivo com outro colaborador, desde que não haja qualquer nível de subordinação hierárquica ou influência em decisões de gestão entre os mesmos. Sempre que houver relação de parentesco ou relacionamento afetivo entre você e outro colaborador da sua mesma cadeia hierárquica, a situação deve ser reportada ao seu gestor imediato para que o caso seja avaliado e, conforme for, os colaboradores sejam remanejados de área.<br /><br />

São condutas intoleráveis pelo Grupo SEB e sujeitas a medidas disciplinares cabíveis:<br /><br />

Assédio moral por meio de ofensas verbais, tratamento humilhante ou ameaças;<br /><br />

Assédio sexual por meio de investidas não desejadas, pedidos de favores sexuais e qualquer conduta inapropriada, verbal ou física, de natureza sexual;<br /><br />

Tratamento desrespeitoso, ameaçador ou discriminatório para com qualquer pessoa, independentemente de nível hierárquico, cargo ou função;<br /><br />

Discriminação em virtude de origem social, raça, cor, gênero, idade, religião, característica física ou orientação sexual;<br /><br />

Utilização para fins particulares ou repasse a terceiros de tecnologias, métodos ou informações de propriedade do Grupo SEB ou de terceiros, em poder do Grupo SEB, sem a devida autorização prévia e expressa;<br /><br />

Desenvolvimento de atividades externas que concorram com os negócios do Grupo SEB;<br /><br />

Utilização de sistemas e recursos do Grupo SEB para atividades que não sejam de cunho profissional estabelecidas para o colaborador, ou ainda para propagação ou divulgação de boatos, pornografia, piadas, jogos ou propaganda políticopartidárias;<br /><br />

Utilização de recursos disponibilizados pela TI para transmissão ou acesso a conteúdo inadequado, tais como: pornografia ou obscenidades, atividades contra o patrimônio público de terceiros; discriminação; terrorismo; propaganda político-partidária e jogos de qualquer natureza, posto que tais ferramentas disponibilizadas são para o desenvolvimento das atividades profissionais ou, ainda, qualquer uso em discordância com a política de segurança de informação do Grupo SEB;<br /><br />

Comercialização de quaisquer produtos ou serviços de caráter particular nas dependências do Grupo SEB;<br /><br />

Atos que possam causar danos ao patrimônio do Grupo SEB;<br /><br />

Oferecimento ou recebimento de presentes em desacordo com as políticas e normas do Grupo SEB, como meio de exercer influência indevida, ou auferir ganho pessoal ou prêmio para si ou para terceiros;<br /><br />

Contratação de parentes para posições em que haja subordinação direta ou indireta, ou potencial conflito de interesses próprios com os interesses do Grupo SEB;<br /><br />

Trabalho sobre efeito de álcool ou drogas ilegais;<br /><br />

Uso de drogas psicoativas ilegais em quaisquer das dependências do Grupo SEB;<br /><br />

Distorção de números ou a caracterização contábil de itens que venham a refletir nos relatórios gerenciais ou nas demonstrações financeiras do Grupo SEB;<br /><br />

Divulgação de informações do Grupo SEB ou comentários de situações de seu cotidiano de trabalho em redes sociais;<br /><br />

Vazamento de dados pessoais de colaboradores, pais e alunos a terceiros, haja vista o princípio da privacidade;<br /><br />

Manifestações em nome do Grupo SEB, inclusive em redes sociais, blogs ou qualquer outro tipo de veículo ou mídia, sem a devida autorização prévia;<br /><br />

Discussão de informações atreladas ao Grupo SEB, ao negócio ou a sua área de trabalho em público, que viole o direito à confiabilidade/confidencialidade.
</div>
</UncontrolledCollapse>


<hr style={{borderWidth:2}} />

<div style={{textAlign:"left" }}>
<Button id="toggler9" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>RELACIONAMENTO COM CLIENTES E SOCIEDADE</Button>
</div>

<UncontrolledCollapse toggler="#toggler9">
<div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
Respeitamos a liberdade de escolha de nossos clientes;<br /><br />

Fornecemos de forma clara e correta as informações necessárias para a tomada de decisão;<br /><br />

Não utilizamos de artifícios como vendas casadas e outras formas de imposição ou constrangimentos na concretização dos negócios;<br /><br />

Atendemos com profissionalismo e agilidade, valorizando seus interesses, tempo, opiniões e sentimentos;<br /><br />

A atuação ocorre segundo os preceitos da livre concorrência e, nesse sentido, as práticas de Truste (uso de informações privilegiadas, omissão de fatos relevantes, manipulação da boa-fé, espionagem) são vedadas;<br /><br />

Tomamos decisões sem influências político-partidárias;<br /><br />

Firmamos parcerias e eventualmente damos contribuições (patrocínios, doações) para o setor público, em conformidade com a legislação cabível e em conformidade com as diretrizes corporativas do Grupo, devidamente registradas em nossos demonstrativos;<br /><br />

Nenhuma contribuição (patrocínio, doações etc.) é realizada tendo em vista a obtenção de benefícios ilegítimos ou favores;<br /><br />

Relacionamo-nos com a mídia baseados em princípios de veracidade e independência;<br /><br />

Apenas alguns Colaboradores previamente selecionados estão autorizados a se manifestar em nome do SEB junto à imprensa e público em geral, inclusive redes sociais. Sempre que receberem o contato de representantes de veículos de comunicação, nossos colaboradores devem procurar o Departamento de comunicação e divulgação de informações ao mercado em geral, inclusive redes sociais, devem ser realizadas exclusivamente pelo Departamento de Comunicação, de forma a garantir a transparência e clareza na comunicação de forma equitativa.<br /><br />

Discernimos sobre o que é dever de informar;
</div>
</UncontrolledCollapse>


<hr style={{borderWidth:2}} />

<div style={{textAlign:"left" }}>
<Button id="toggler10" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>COMISSÃO DE COMPLIANCE</Button>
</div>

<UncontrolledCollapse toggler="#toggler10">
<div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
A partir da aprovação desta Política, deverá ser criada pela Diretoria de Serviços a Comissão de Compliance, que terá o papel de guardião deste instrumento;<br /><br />

Essa Comissão é multidisciplinar e capaz de apoiar todos os colaboradores no esclarecimento e aderência aos assuntos relacionados à presente política.<br /><br />

O comitê é formado por representantes das áreas:<br />
• Diretoria Executiva<br />
• Jurídico<br />
• Gestão de Gente<br />
• Controladoria<br />
• Auditoria Interna<br /><br />

Cada área acima especificada deverá indicar um membro titular e outro suplente, que deverão se fazer presentes mensalmente nas reuniões ordinárias da referida Comissão. A ausência do titular e ou do suplente por mais de duas reuniões seguidas ocasionará a substituição compulsória do referido membro.<br /><br />

Periodicidade:<br /><br />

Reuniões Ordinárias da Comissão de Compliance: uma vez por mês, a ser definida e apresentada através de uma agenda de eventos, divulgada em todos os meios de comunicação interna e quando da assinatura do termo de posse por parte do membro titular e seu respectivo suplente;<br /><br />

Reuniões Extraordinárias: sempre que houver necessidade em decorrência da urgência e relevância das denúncias, irregularidades ou fatos que chegaram ao conhecimento através dos canais disponíveis.

Compete à Comissão de Compliance:<br /><br />

Disseminar a cultura de respeito às regras e aos princípios desta Política, promovendo treinamentos, cursos, palestras e Workshops específicos;<br /><br />

Promover a fiscalização constante quanto ao cumprimento desta Política, realizando auditorias e verificações específicas;
Receber denúncias e investigar qualquer conduta compreendida como inadequada e praticada pelos colaboradores do Grupo SEB;
Instaurar processo disciplinar investigatório;<br /><br />

Em todos esses procedimentos, a Comissão de Compliance poderá requisitar a ajuda e o fornecimento de informações dos departamentos do Grupo SEB;<br /><br />

A Comissão de Compliance deverá ser formada, tanto por profissionais do Grupo SEB, quanto por profissionais/parceiros contratados especificamente para esse fim;<br /><br />

Os profissionais deverão ser pessoas habilitadas e designadas para fiscalizar o respeito e a observância dos princípios éticos e regras de conduta aqui estabelecidas, bem como cuidar da manutenção e atualização desta Política.
</div>
</UncontrolledCollapse>


<hr style={{borderWidth:2}} />

<div style={{textAlign:"left" }}>
<Button id="toggler11" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>DESCUMPRIMENTO DESTA POLÍTICA</Button>
</div>

<UncontrolledCollapse toggler="#toggler11">
<div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
Os Colaboradores poderão utilizar o canal de comunicação, através do e-mail ouvidoria@sebsa.com.br, o qual assegurará total anonimato, para comunicação de quaisquer violações desta Política;<br /><br />

As denúncias serão recebidas e, após avaliação da ouvidoria, terão o devido encaminhamento para a Comissão de Compliance, ocasião em que serão observados os procedimentos que objetivem resguardar os direitos do denunciante e do denunciado; Sob nenhuma circunstância, a denúncia de quaisquer potenciais violações ou possíveis improbidades servirá como base para retaliação ou intimidação de qualquer empregado;<br /><br />

Deverá ser instaurado processo disciplinar investigatório, tendo o prazo máximo de 60 (sessenta) dias para sua conclusão e mais 30 (trinta) dias para resolução;<br /><br />

Nos casos de assédio moral e/ou sexual, o prazo previsto no item acima deverá ser reduzido pela metade. O funcionário sob investigação terá o prazo de 15 (quinze) dias, contados da conclusão da investigação, para apresentar sua justificativa, garantindo seu direito ao contraditório e à ampla defesa;<br /><br />

Após a apresentação da defesa, a Comissão de Compliance deverá finalizar a investigação e proferir a resolução em 30 (trinta dias), contados da apresentação da justificativa do denunciado;<br /><br />

Violações aos dispositivos constantes desta Política sujeitam aos infratores as medidas disciplinares, que incluem: advertência (verbal ou formal), suspensão e demissão por justa causa, sem prejuízo das responsabilizações legais cabíveis;<br /><br />

Na aplicação das medidas disciplinares, serão consideradas a natureza e a gravidade da infração;<br /><br />

Em respeito aos princípios do Grupo SEB, todo o processo de investigação tramitará em caráter de sigilo, o qual só será acessível à Comissão de Compliance, ao denunciado e seu procurador, caso seja constituído;<br /><br />

A quebra do sigilo acarretará responsabilização civil e criminal do responsável;<br /><br />

Uma vez concluído todo o processo de investigação e resolução e tendo sido comprovado o comportamento inadequado e desconforme com as Diretrizes desta Política, a Comissão de Compliance poderá, dentro dos limites da ética, utilizar determinada situação como exemplo, difundindo-a dentro do Grupo SEB para que todos tenham conhecimento da sua inadequação.
</div>
</UncontrolledCollapse>



<hr style={{borderWidth:2}} />

<div style={{textAlign:"left" }}>
<Button id="toggler12" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>CONSIDERAÇÕES FINAIS</Button>
</div>

<UncontrolledCollapse toggler="#toggler12">
<div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
Dúvidas ou questões sobre a interpretação, o alcance ou qualquer ponto desta Política deverão ser apreciadas pela Comissão de Compliance, através dos meios de comunicação disponíveis no site do Grupo SEB, especialmente pelo e-mail: auditoriainterna@sebsa.com.br;
</div>
</UncontrolledCollapse>
           
        </Container>
       
</div>  
           
 


    </>
  );
}

export default Politicas;
