import React from "react";
// plugin that creates slider


// reactstrap components
import {
  Container,
} from "reactstrap";

function UniSEB() {
 
  return (
    <>
      <div style={{marginTop:150}}>
        <Container>
          <div style={{textAlign:"left", marginTop:50, marginBottom:20, fontSize:32, fontWeight:600}}>
          Aos ex-alunos UniSEB
          </div>
          <div style={{textAlign:"left", marginBottom:50, fontSize:16, fontWeight:400, lineHeight:2}}>
          Comunicamos aos alunos formados entre os anos de 2003 e 2007, pelo então UniSEB, que promoveremos a restituição do montante pago pela emissão dos diplomas de conclusão do curso. Caso tenha interesse, encaminhar ao e-mail <a href="mailto:restituição.certificado@sebsa.com.br" style={{fontSize:16, fontWeight:600, color:"#02CFAA"}}>restituição.certificado@sebsa.com.br</a> , até o dia 03/12/2021, os seguintes dados: nome completo, RG, CPF, telefone e cópia do diploma ou outro documento de identificação estudantil da época. A restituição será realizada com a devida correção monetária pela SELIC, a contar de 27/11/2007, conforme formalizado com o Ministério Público Federal nos autos da Ação Civil Pública de nº 0014733-32.2007.4.03.6102.<br /><br />

Os valores não restituídos aos alunos serão destinados à FAEPA, Fundação de Apoio ao Ensino, Pesquisa e Assistência do Hospital das Clínicas da Faculdade de Medicina de Ribeirão Preto, da Universidade de São Paulo.
          </div>
        </Container>
       
</div>  


    </>
  );
}

export default UniSEB;
