import React, {useState} from 'react';
// plugin that creates slider
import classnames from 'classnames';

// reactstrap components
import {
  Container, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, UncontrolledCollapse
} from "reactstrap";

import Logo from "../../assets/img/logoSE2.png";

function Privacidade() {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  return (
    <>
      <div style={{marginTop:50, marginBottom:50}}>
        <Container>

          <div style={{textAlign:"center",}}>
          <img src={Logo} width="120" />
          </div>

          <div style={{marginTop:50}}>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
            style={{cursor:"pointer", padding:0, marginBottom:30, marginRight:50, fontWeight:600, fontSize:20}}
          >
            Política de Privacidade
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
            style={{cursor:"pointer", padding:0, marginBottom:30, fontWeight:600, fontSize:20}}
          >
            Política de Cookies
          </NavLink>
        </NavItem>
      </Nav>
<hr  />

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm>
                  <div style={{textAlign:"left" }}>
                <Button id="toggler1" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>1. POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS PESSOAIS</Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler1">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br />

<span style={{fontWeight:600}}>SEB SISTEMA EDUCACIONAL BRASILEIRO S.A (“Grupo SEB”)</span><br />
Sede: Rua Deolinda, 70 – Jardim Macedo, Ribeirão Preto – São Paulo, CEP 14091-018<br />
CNPJ: 56.012.628/0001-61<br /><br />

A presente Política tem por finalidade demonstrar o nosso compromisso em resguardar a sua privacidade e proteger seus Dados Pessoais, estabelecendo as regras sobre o Tratamento, bem como explicando quais são seus direitos e como exercê-los, e, ainda, referida política é aplicável para todas as Escolas e operações vinculadas ao Grupo SEB S/A. <br /><br />

Leia atentamente esta Política e, caso ainda restem dúvidas, fique à vontade para entrar em contato conosco por meio dos Canais de Atendimento aqui disponibilizados.

                </div>
                </UncontrolledCollapse>
          
                <hr style={{borderWidth:2}} />


                <div style={{textAlign:"left" }}>
                <Button id="toggler2" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>2. CONCEITOS BÁSICOS</Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler2">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br />
Para melhor entendimento desta Política, devem ser consideradas as seguintes definições:<br /><br />

<span style={{fontWeight:600}}>Cookies:</span> pequenos arquivos enviados para o seu navegador ou dispositivos, que armazenam as suas preferências e outras informações sobre como e quando Nossos Ambientes são visitados, bem como a quantidade de pessoas que os acessam;<br /><br />

<span style={{fontWeight:600}}>Dados Pessoais ou Dados:</span> são os dados relativos a uma pessoa física, que sejam capazes de identificá-la ou torná-la identificável. Por exemplo: Nome, e-mail, número do RG, preferências pessoais, endereço IP, geolocalização etc.;<br /><br />

<span style={{fontWeight:600}}>Dados Pessoais Sensíveis:</span> são quaisquer Dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dados referentes à saúde ou à vida sexual, dados genéticos ou biométricos, quando vinculados a uma pessoa física;<br /><br />

<span style={{fontWeight:600}}>Encarregado ou Data Protection Officer (DPO): </span>pessoa indicada pelo Grupo SEB para atuar como canal de comunicação entre nós, os Titulares dos Dados Pessoais e a Autoridade Nacional de Proteção de Dados (ANPD);<br /><br />

<span style={{fontWeight:600}}>Legislação Aplicável:</span> toda legislação que verse sobre privacidade e proteção de Dados Pessoais, especialmente a Lei nº 13.709/2018 (Lei Geral de Proteção de Dados Pessoais – LGPD);<br /><br />

<span style={{fontWeight:600}}>Nossos Ambientes:</span> designa o endereço eletrônico https://www.sebsa.com.br e demais sites vinculados ao Grupo SEB S/A, inclusive de operações diversas;<br /><br />

<span style={{fontWeight:600}}>Política:</span> é esta Política de Privacidade e Tratamento de Dados Pessoais;<br /><br />

<span style={{fontWeight:600}}>Titular dos Dados Pessoais:</span> é você, a pessoa física a quem os Dados Pessoais se referem, seja na condição de usuário dos nossos ambientes;<br /><br />

<span style={{fontWeight:600}}>Tratamento:</span> toda operação realizada com Dados Pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.




                </div>
                </UncontrolledCollapse>
          
                <hr style={{borderWidth:2}} />


                <div style={{textAlign:"left" }}>
                <Button id="toggler3" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>3. DADOS CADASTRAIS QUE COLETAMOS</Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler3">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br />Os seguintes dados poderão ser coletados quando você interage com Nossos Ambientes:<br /><br />

         
I.	Unidade de interesse;<br />
II.	Tipo de atendimento (Online ou presencial);<br />
III.	Série de interesse;<br />
IV.	Nome Completo do responsável;<br />
V.	Sexo;<br />
VI.	Estado Civil;<br />
VII.	Naturalidade;<br />
VIII.	Nacionalidade;<br />
IX.	Telefone celular responsável;<br />
X.	Telefone fixo;<br />
XI.	E-mail do responsável;<br />
XII.	Nome completo do aluno;<br />
XIII.	RG do responsável;<br />
XIV.	CPF do responsável;<br />
XV.	RG do Aluno;<br />
XVI.	CPF do aluno;<br />
XVII.	Onde ficou sabendo da escola;<br />
XVIII.	Grau de parentesco com o estudante;<br />
XIX.	Escolaridade;<br />
XX.	Data de nascimento;<br />
XXI.	CEP;<br />
XXII.	Endereço Completo;<br />
XXIII.	Dados bancários ou de cartão de crédito ou débito;<br />
XXIV.	Dados referentes à saúde.<br /><br />




<span style={{fontWeight:600}}>Para que coletamos esses dados cadastrais?</span><br />
I.	Identificar e autenticar Você;<br />
II.	Cumprir as obrigações decorrentes do uso dos nossos serviços;<br />
III.	Ampliar nosso relacionamento, informando você sobre novidades, funcionalidades, conteúdos, notícias e demais eventos que consideramos relevantes para você;<br />
IV.	Enriquecer sua experiência conosco e promover nossos produtos e serviços;<br />
V.	Cumprir nossas obrigações legais e regulatórias.<br /><br />
</div>
</UncontrolledCollapse>

<hr style={{borderWidth:2}} />

<div style={{textAlign:"left" }}>
                <Button id="toggler24" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>
                4. DADOS DE IDENTIFICAÇÃO DIGITAL
                </Button>
                </div>

                <UncontrolledCollapse toggler="#toggler24">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>

<span style={{fontWeight:600}}>O que Coletamos:</span><br />
I.	Endereço IP e Porta Lógica de Origem;<br />
II.	Dispositivo (versão do sistema operacional);<br />
III.	Registros de data e horário cada ação que você realizar;<br />
IV.	ID da sessão;<br />
V.	Sessões;<br />
VI.	Cookies.<br /><br />


<span style={{fontWeight:600}}>Para que coletamos esses dados?</span><br />
I.	Identificar e autenticar Você;<br />
II.	Cumprir com obrigações legais de manutenção de registros estabelecidas pelo Marco Civil da Internet (Lei nº12.965/2014);<br />
III.	Cumprir nossas obrigações legais e regulatória;<br />
IV.	Manter registro de nossas operações de tratamento de dados pessoais, conforme estabelecido pela Lei Geral de Proteção de Dados (Lei nº 13.709/2018);<br />
V.	Aprimorar nosso site/aplicativo.<br /><br />


<span style={{fontWeight:600}}>Dados Necessários:</span> Nossos serviços dependem diretamente de alguns dos dados informados acima. Podemos ficar impossibilitados de prestar total ou parcialmente nossos serviços na falta de alguns desses dados.<br /><br />

<span style={{fontWeight:600}}>Atualização e Veracidade dos Dados:</span> Você é o único responsável pela precisão, veracidade ou atualização dos Dados que nos fornece. Nós não somos obrigados a tratar os seus Dados se houver razões para crer que tal Tratamento possa nos imputar infração de qualquer lei aplicável, ou se você estiver utilizando nossos ambientes para quaisquer fins ilegais ou ilícitos.<br /><br />

<span style={{fontWeight:600}}>Base de Dados:</span> A base de dados formada por meio da coleta de Dados é de nossa propriedade e está sob nossa responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necessários, serão feitos dentro dos limites e propósitos descritos nesta Política.<br /><br />

<span style={{fontWeight:600}}>Tecnologias Empregadas:</span> Nós utilizamos Cookies em nossos ambientes, cabendo a você configurar o seu navegador de internet para permitir ou não seu uso. Nesta hipótese, algumas funcionalidades que oferecemos poderão ser limitadas. Para maiores informações, acesse nossa Política de Cookies.
                </div>
                </UncontrolledCollapse>
          
                <hr style={{borderWidth:2}} />



                <div style={{textAlign:"left" }}>
                <Button id="toggler4" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>
                5. COMO COMPARTILHAMOS OS DADOS
                </Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler4">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br /><span style={{fontWeight:600}}>Hipóteses de compartilhamento dos Dados:</span> Os Dados coletados e as atividades registradas (logs) podem ser compartilhados:<br /><br />

                I.	Com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver determinação legal, requerimento, requisição ou ordem nesse sentido;<br />

                II.	Com as empresas e áreas de negócios do Grupo SEB, as quais estão de acordo com esta Política;<br />

                III.	Com prestadores de serviço ou empresas parceiras, para facilitar, prover ou executar atividades relacionadas aos Nossos Ambientes;<br />

                IV.	Com empresas de marketing e publicidade, para entregar as promoções e informações adequadas ao seu perfil;<br />

                V.	De forma automática, em caso de movimentações societárias, como fusão, aquisição ou incorporação da Grupo SEB.<br /><br />
              

                Caso você tenha qualquer dúvida sobre com quem compartilhamos seus Dados, entre em contato conosco por meio dos Canais de Atendimento disponibilizados no final desta Política.
                </div>
                </UncontrolledCollapse>
          
                <hr style={{borderWidth:2}} />


                <div style={{textAlign:"left" }}>
                <Button id="toggler5" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>
                6. COMO PROTEGEMOS SEUS DADOS E COMO VOCÊ TAMBÉM PODERÁ PROTEGÊ-LOS
                </Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler5">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br /><span style={{fontWeight:600}}>Práticas de Segurança e Governança:</span> Para resguardar a sua privacidade e proteger os seus Dados, nós contamos com um programa de governança que contém regras de boas práticas, políticas e procedimentos internos, os quais estabelecem condições de organização, treinamentos, ações educativas e mecanismos de supervisão e mitigação de riscos relacionados ao Tratamento de Dados Pessoais.<br /><br />

                <span style={{fontWeight:600}}>Acesso aos Dados, Proporcionalidade e Relevância:</span> Internamente, os Dados coletados são acessados somente por profissionais devidamente autorizados, respeitando os princípios de proporcionalidade, necessidade e relevância para os objetivos do nosso negócio, além do compromisso de confidencialidade e preservação da sua privacidade nos termos desta Política.<br /><br />


                <span style={{fontWeight:600}}>Adoção de Boas Práticas:</span>  Você também é responsável pelo sigilo de seus Dados e deve ter sempre ciência de que o compartilhamento de senhas e dados de acesso e/ou, inclusive o compartilhamento de senhas, viola esta Política e pode comprometer a segurança dos seus Dados e dos Nossos Ambientes. Caso você identifique ou tome conhecimento sobre o comprometimento da segurança dos seus Dados, entre em contato com o nosso Encarregado por meio dos Canais de Atendimento disponibilizados no final desta Política.<br /><br />




                <span style={{fontWeight:600}}>Links Externos:</span> Quando você utilizar Nossos Ambientes, você poderá ser conduzido, via link, a outros sites, portais ou plataformas, que poderão coletar seus Dados e ter sua própria política de privacidade. Caberá a você ler as referidas políticas, sendo de sua responsabilidade aceitá-las ou rejeitá-las. Nós não somos responsáveis pelas políticas de privacidade de terceiros e nem pelo conteúdo de quaisquer sites ou serviços ligados à ambientes que não os nossos.<br /><br />

                <span style={{fontWeight:600}}>Tratamento por Terceiros Sob Nossa Diretriz:</span> Nós buscamos avaliar cuidadosamente nossos parceiros e prestadores de serviços e firmamos com eles obrigações contratuais de confidencialidade, segurança da informação e proteção de Dados, com objetivo de proteger você.
                </div>

             
                </UncontrolledCollapse>
          
                <hr style={{borderWidth:2}} />

                <div style={{textAlign:"left" }}>
                <Button id="toggler6" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>
                7. COMO ARMAZENAMOS SEUS DADOS E O REGISTRO DE ATIVIDADES (LOGS)
                </Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler6">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br /><span style={{fontWeight:600}}>Local de armazenamento:</span> Os Dados coletados e os registros de atividades (logs) são armazenados em ambiente seguro e controlado, podendo estar em nossos servidores localizados no Brasil, bem como em ambiente de uso de recursos ou servidores na nuvem (Cloud Computing),o que poderá exigir transferência e/ou processamento dos seus Dados fora do Brasil. Essas transferências envolvem apenas empresas que demonstrem estar em conformidade com as legislações aplicáveis, mantendo um nível de conformidade semelhante ou mais rigoroso que o previsto na legislação brasileira.<br /><br />

                <span style={{fontWeight:600}}>Prazo de Armazenamento:</span> Nós armazenamos os Dados somente pelo tempo que for necessário para cumprir com as finalidades para as quais foram coletados ou para cumprimento de quaisquer obrigações legais, regulatórias ou para preservação de direitos.<br /><br />

                <span style={{fontWeight:600}}>Descarte dos Dados:</span> Findos o prazo de manutenção e a necessidade legal, os Dados serão excluídos com uso de métodos de descarte seguro ou utilizados de forma anonimizada para fins estatísticos.
                </div>


                </UncontrolledCollapse>
          
                <hr style={{borderWidth:2}} />


                <div style={{textAlign:"left" }}>
                <Button id="toggler7" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>
                8. QUAIS SÃO OS SEUS DIREITOS E COMO EXERCÊ-LOS
                </Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler7">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br /><span style={{fontWeight:600}}>Seus direitos básicos:</span> Os Dados são seus e a Legislação aplicável traz uma série de direitos relacionados a eles, que poderão ser exercidos por você através de requisição ao nosso Encarregado pelo Canal de Atendimento disponibilizado no final desta Política.<br /><br />

                <span style={{fontWeight:600}}>(I)</span> Confirmação e acesso: você poderá solicitar a confirmação sobre a existência de Tratamento e o acesso a seus Dados, inclusive por meio da solicitação de cópias de registros que temos sobre você.<br /><br />

                <span style={{fontWeight:600}}>(II)</span> Correção: você poderá solicitar a correção de seus Dados que estejam incompletos, inexatos ou desatualizados.<br /><br />

                <span style={{fontWeight:600}}>(III)</span> Anonimização, bloqueio ou eliminação: você poderá solicitar a anonimização dos seus Dados, para que eles não possam mais ser relacionados a você, o bloqueio dos seus Dados, suspendendo temporariamente a possibilidade de Tratamento para certas finalidades, ou a eliminação dos seus Dados.<br /><br />

                <span style={{fontWeight:600}}>(IV)</span> Portabilidade: você poderá solicitar que nós forneçamos seus Dados em formato estruturado e interoperável visando sua transferência para um terceiro, respeitando nossa propriedade intelectual ou segredo de negócios.<br /><br />

                <span style={{fontWeight:600}}>(V)</span> Informação sobre compartilhamento: você poderá solicitar informações sobre terceiros com os quais compartilhamentos seus Dados, limitando essa divulgação a informações que não violem nossa propriedade intelectual ou segredo de negócios.<br /><br />

                <span style={{fontWeight:600}}>(VI)</span> Revogação do consentimento: você poderá optar por retirar o consentimento para alguma finalidade que você tenha consentido. Essa revogação não afetará a legalidade de qualquer Tratamento realizado anteriormente. Se você retirar seu consentimento para finalidades fundamentais ao regular funcionamento dos nossos ambientes e serviços, estes poderão ficar indisponíveis para você.<br /><br />

                <span style={{fontWeight:600}}>(VII)</span> Oposição: você poderá se opor ao Tratamento dos seus Dados, caso não concorde com alguma finalidade.<br /><br />

                <span style={{fontWeight:600}}>Requisição:</span> Para sua segurança, sempre que você apresentar uma requisição para exercícios dos seus direitos, nós poderemos solicitar informações complementares para comprovar sua identidade, buscando impedir fraudes.<br /><br />

                <span style={{fontWeight:600}}>Não atendimento de requisições:</span> Nós poderemos deixar de atender alguma requisição de exercício de direitos, caso o atendimento viole nossa propriedade intelectual ou segredo de negócios, bem como quando houver obrigação legal ou regulatória para retenção de Dados. Além disso, podemos deixar de atender a sua requisição se precisarmos reter os Dados para possibilitar nossa defesa ou de terceiros em disputas de qualquer natureza.<br /><br />

                <span style={{fontWeight:600}}>Respostas às requisições:</span> Nós nos comprometemos a responder todas as requisições em um prazo razoável e sempre em conformidade com a legislação aplicável
                </div>





                
                </UncontrolledCollapse>
          
                <hr style={{borderWidth:2}} />



                <div style={{textAlign:"left" }}>
                <Button id="toggler8" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>
                9. INFORMAÇÕES SOBRE ESSA POLÍTICA
                </Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler8">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br /><span style={{fontWeight:600}}>Alteração do teor e atualização:</span> Você reconhece o nosso direito de alterar o teor desta Política a qualquer momento, conforme a finalidade ou necessidade, cabendo a você verificá-la sempre que efetuar o acesso em nossos ambientes.<br/> <br/>

                <span style={{fontWeight:600}}>Inaplicabilidade:</span> Esta Política deve ser revisada sempre que existir a necessidade de alterações nos critérios definidos nas demais normas e políticas específicas do Grupo SEB.<br/> <br/>

                <span style={{fontWeight:600}}>Lei aplicável e foro:</span> Essa Política será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o foro do seu domicílio para dirimir qualquer controvérsia que envolva este documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.<br /><br />

                <span style={{fontWeight:600}}>Atualização:</span> 20 de março de 2024.
                </div>
                </UncontrolledCollapse>
          
                <hr style={{borderWidth:2}} />


                <div style={{textAlign:"left" }}>
                <Button id="toggler9" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>
                10. CANAIS DE ATENDIMENTO E CONTATO DO DPO
                </Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler9">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br /> Em caso de qualquer dúvida com relação às disposições constantes desta Política, inclusive para exercício dos seus direitos, você poderá entrar em contato com nosso Encarregado, que está à disposição nos seguintes endereços:<br /><br />

                <span style={{fontWeight:600}}>Encarregado:</span> Vinícius José Santos Moraes.<br />
                <span style={{fontWeight:600}}>E-mail para contato:</span> dpo@sebsa.com.br  |  Celular: (19) 9 98571830




                </div>
                </UncontrolledCollapse>
          


            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">   
          <Row>
            <Col sm>
            <div style={{textAlign:"left" }}>
                <Button id="toggler10" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>
                1. POLÍTICA DE COOKIES DA PLATAFORMA INSTITUCIONAL
                </Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler10">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
              
                Esta Política de Cookies (“Política”) aplica-se à Plataforma Institucional (“Plataforma”) do <span style={{fontWeight:600}}>SEB SISTEMA EDUCACIONAL BRASILEIRO S.A</span> (“Grupo SEB”), com sede à Rua Deolinda, nº 70, Jardim Macedo, Ribeirão Preto - São Paulo, CEP 14091-018, CNPJ: 56.012.628/0001-61 e demais escolas e operações relacionadas ao Grupo SEB S/A, direta ou indiretamente.<br /><br />

<span style={{fontWeight:600}}>Introdução</span><br /><br />

Nosso intuito é proporcionar uma experiência ainda mais transparente ao Usuário que acessa e utiliza nossa Plataforma, explicando o conceito de cookies, como os utilizamos e, ainda, as possibilidades de customização do seu uso.<br /><br />

Caso não concorde com o uso de cookies da forma apresentada, o Usuário poderá ajustar as configurações de seu navegador de internet para não permitir o uso de cookies ou não se cadastrar e utilizar a Plataforma.<br /><br />

Importante mencionar que a desabilitação do uso de cookies poderá impactar sua experiência ao utilizar a nossa Plataforma. Recomendamos também a leitura da nossa Política de Privacidade, na qual trazemos mais informações sobre nosso compromisso com a privacidade do Usuário e a proteção dos seus dados pessoais.<br /><br />

Para quaisquer questões ou dúvidas sobre esta Política, o Usuário poderá entrar em contato pelo canal dpo@sebsa.com.br.

                </div>
                </UncontrolledCollapse>
          
                <hr style={{borderWidth:2}} />


                <div style={{textAlign:"left" }}>
                <Button id="toggler11" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>
                2. O QUE SÃO COOKIES
                </Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler11">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br />
                Cookies são pequenos arquivos digitais em formato de texto que são armazenados no dispositivo eletrônico do Usuário quando ele acessa nossa Plataforma. Os cookies guardam informações relacionadas às suas preferências, como idioma preferido, localização, recorrência das suas sessões e outras variáveis que os desenvolvedores da Plataforma consideram relevantes para tornar a sua experiência muito mais eficiente.
                </div>
                </UncontrolledCollapse>
          
                <hr style={{borderWidth:2}} />



                <div style={{textAlign:"left" }}>
                <Button id="toggler12" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>
                3. PARA QUE SERVEM OS COOKIES
                </Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler12">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br />
                Os cookies servem para aprimorar a experiência do Usuário, tanto em termos de performance, como em termos de usabilidade, uma vez que os conteúdos disponibilizados serão direcionados às suas necessidades e expectativas. 
                <br/ ><br />
Eles também podem ser utilizados para realizar estatísticas anônimas que permitem entender como os Usuários utilizam a Plataforma, bem como para aprimorar suas estruturas e conteúdo. Por serem estatísticas anônimas, não é possível identificá-lo pessoalmente por meio desses dados.

                </div>
                </UncontrolledCollapse>
          
                <hr style={{borderWidth:2}} />



                <div style={{textAlign:"left" }}>
                <Button id="toggler13" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>
                4. COOKIES UTILIZADOS NA PLATAFORMA
                </Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler13">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br />

                
                Nós utilizamos dois tipos de cookies na nossa Plataforma:<br /><br />

                <span style={{fontWeight:600}}>Cookies de Sessão:</span> São os cookies temporários que permanecem arquivados até que o Usuário saia da Plataforma ou encerre o navegador.<br /><br />

                <span style={{fontWeight:600}}>Cookies Persistentes:</span> são os cookies que ficam armazenados no dispositivo do Usuário até que sejam excluídos (o tempo que o Cookie permanecerá no dispositivo depende de sua "validade" e das configurações do seu navegador de internet). São esses cookies que são utilizados no acesso à Plataforma, mostrando os conteúdos mais relevantes e personalizados de acordo com os interesses do Usuário.<br /><br />

                <span style={{fontWeight:600}}>Os cookies (de Sessão ou Persistentes) podem ser categorizados de acordo com sua função:</span><br /><br />

                <span style={{fontWeight:600}}>Cookies Estritamente Necessários: </span>permitem a navegação e utilização das aplicações, bem como acessar a áreas seguras da Plataforma. Sem estes cookies, a Plataforma não funciona corretamente.<br /><br />

                <span style={{fontWeight:600}}>Cookies Analíticos: </span>coletam dados estatísticos anônimos com a finalidade de analisar a utilização da Plataforma e seu respectivo desempenho. Esse tipo de cookies é essencial para mantermos uma performance positiva, como: entender quais são as páginas mais populares e verificar o motivo dos erros apresentados nas páginas. Esses cookies não coletam nenhum dado pessoal.<br /><br />

                <span style={{fontWeight:600}}>Cookies de Funcionalidade:</span> são utilizados para assegurar a disponibilização de funcionalidades adicionais da Plataforma ou para guardar as preferências definidas pelo Usuário ao navegar na internet, sempre que utilizar o mesmo dispositivo.<br /><br />

                <span style={{fontWeight:600}}>Cookies de Publicidade:</span> coletam as informações de visita em nossa Plataforma para que as propagandas nela dispostas sejam mais relevantes para o Usuário e de acordo com os seus interesses. Geralmente estão atrelados a cookies de propriedade de terceiros. Caso você desabilite estes cookies, note que ainda visualizará as publicidades, mas elas serão personalizadas às suas preferências.
                </div>
                </UncontrolledCollapse>
          
                <hr style={{borderWidth:2}} />



                <div style={{textAlign:"left" }}>
                <Button id="toggler14" style={{marginBottom:0, color:"#02CFAA", fontSize:20, backgroundColor:"transparent", fontWeight:600, padding:0, borderWidth:0}}>
                5. COMO CONTROLAR OU ELIMINAR COOKIES
                </Button>
                </div>
                
                <UncontrolledCollapse toggler="#toggler14">
                <div style={{textAlign:"left", marginBottom:20, fontSize:16, fontWeight:400, lineHeight:2}}>
                &nbsp;<br />


                A maioria dos navegadores é configurada para aceitar automaticamente os cookies.<br /><br />

O Usuário pode alterar as configurações para bloquear o seu uso ou alertá-lo quando um cookie estiver sendo enviado para seu dispositivo eletrônico.<br /><br />

Existem várias formas de gerenciar cookies. Consulte as instruções ou a seção de ajuda, tools ou edit, do seu navegador para saber mais sobre como ajustar ou alterar essas configurações.<br /><br />

Disponibilizamos abaixo alguns links para os navegadores mais conhecidos:<br /><br />
<ul>
<li>Firefox</li>
<li>Chrome</li>
<li>Internet Explorer</li>
<li>Safari</li>
<li>Opera</li>
<li>Microsoft Edge</li>
</ul>                </div>
                </UncontrolledCollapse>
          
            </Col>
          </Row>           
        </TabPane>
      </TabContent>
    </div>


          
          
           
        </Container>
       
</div>  
           
 


    </>
  );
}

export default Privacidade;
