import React from "react";
import ReactDOM from "react-dom";
import {history} from './views/history';
import { Router, Route, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import Index from "views/Index.js";
import IndexEng from "views/IndexEng";
import Index2 from "views/Index2.js";
import Index3 from "views/Index3.js";
import Index4 from "views/Index4.js";
import Index5 from "views/Index5.js";

// others

ReactDOM.render(
  <Router history={history}>
    <Switch>
    <Route 
    exact path="/" 
    component={Index} />
      <Route
        path="/uniseb"
        component={Index2} />

<Route
        path="/en"
        component={IndexEng}
      />
    
      <Route
        path="/conduta"
        component={Index3}
      />
       <Route
        path="/politicas"
        component={Index4}
      />
      <Route
        path="/privacidade-cookies"
        component={Index5}
      />
      <Route path="*" component={() => <h1>Página não encontrada! Erro 404.</h1>} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
