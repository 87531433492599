
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
import { Link, animateScroll as scroll } from "react-scroll";
import Logo from "../../assets/img/logoSEB.png";
import IcoEng from "../../assets/img/united-states.png";
import IcoBra from "../../assets/img/brazil.png";
// reactstrap components
import {
  Button,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbarEng() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-white");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };


  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container >
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"           
            title="Grupo SEB"
          >
             <Link to="top" spy={true} smooth={true} offset={-50} duration={1000} style={{cursor:"pointer"}}>
            <img src={Logo} width="80" />
            </Link>
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>            
            <NavItem>
              <NavLink>
                  <Link to="quemsomos" spy={true} smooth={true} offset={-130} duration={1000} style={{textTransform:"none", fontSize:12, color:"#333", fontWeight:400, cursor:"pointer"}}>About us</Link>                
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink>
                  <Link to="marcas" spy={true} smooth={true} offset={-100} duration={1000} style={{textTransform:"none", fontSize:12, color:"#333", fontWeight:400, cursor:"pointer"}}>Our brands</Link>                
              </NavLink>
            </NavItem>
            <NavItem>     
              <NavLink
                href="https://www.institutoseb.org.br/"
                target="_blank"
                style={{textTransform:"none", fontSize:12, color:"#333", fontWeight:400, cursor:"pointer"}}
              >
                Instituto
              </NavLink>
            </NavItem>
            
            <NavItem>
     

            <NavLink
                href="/conduta"
                style={{textTransform:"none", fontSize:14, color:"#333", fontWeight:400, textTransform:"none", fontSize:12, color:"#333", fontWeight:400, cursor:"pointer"}}
              >
                 Conduct and Ethics            
              </NavLink>
              
            </NavItem>
            <NavItem>
            <UncontrolledDropdown style={{borderColor:"transparent", margin:0, padding:0}}>
              <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                data-toggle="dropdown"
                id="dropdownMenuButton2"
                style={{ backgroundColor:"transparent", fontSize:12, borderColor:"transparent", color:"#333", textTransform:"none", padding:8,}}
              >
                Work with us
              </DropdownToggle>
              <DropdownMenu>
                <div style={{ backgroundColor:"#fff", borderRadius:12, marginTop:10,}}>
                <DropdownItem style={{textTransform:"none",}} href="https://gruposeb.gupy.io" target="_Blank">
                Opportunities 
                </DropdownItem>
                </div>
                <div style={{ backgroundColor:"#fff",}}>
                <DropdownItem tyle={{textTransform:"none", backgroundColor:"#fff", height:80}} href="https://jovenstalentos.sebsa.com.br" target="_Blank">
                Young Talents
                </DropdownItem> 
                </div>
                <div style={{ backgroundColor:"#fff", marginBottom:10, borderRadius:12}}>
                <DropdownItem tyle={{textTransform:"none", backgroundColor:"#fff", height:80}} href="https://programadeestagio.sebsa.com.br" target="_Blank">
                Internship program
                </DropdownItem>    
                </div>           
              </DropdownMenu>
            </UncontrolledDropdown>
            </NavItem>
            <NavItem>
  

            <UncontrolledDropdown style={{borderColor:"transparent", margin:0, padding:0}}>
              <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                data-toggle="dropdown"
                id="dropdownMenuButton"
                style={{backgroundColor:"transparent", fontSize:12, borderColor:"transparent", color:"#333", textTransform:"none", padding:8}}
              >
               Employee Area
              </DropdownToggle>
              <DropdownMenu>
              <div style={{ backgroundColor:"#fff", borderRadius:12, marginTop:10,}}>
                <DropdownItem style={{textTransform:"none",}} href="https://outlook.office365.com"  target="_Blank">
                Corporate email
                </DropdownItem>
              </div>
              <div style={{ backgroundColor:"#fff", borderRadius:12, display:"none", marginBottom:10,}}>
                <DropdownItem href="https://rh.sebsa.com.br/recibopagto" target="_Blank">
                Payslip
                </DropdownItem> 
                </div>               
              </DropdownMenu>
            </UncontrolledDropdown>

       
            </NavItem> 
            <NavItem>        
            <NavLink
                href="https://www.linkedin.com/company/gruposeb/"
                target="_blank"
              >
                <i className="fa fa-linkedin" />
              </NavLink>
            </NavItem>   
            <NavItem>        
            <NavLink
                href="https://www.youtube.com/channel/UC8uUQ0T2dvZXTA9SCypMBvQ/videos"
                target="_blank"
              >
                <i className="fa fa-youtube" />
              </NavLink>
            </NavItem>

            <NavItem>        
            <NavLink
                href="/"
            >
                 <img src={IcoBra} width="20" />
              </NavLink>
            </NavItem>
          </Nav>

         
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbarEng;
