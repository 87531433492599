import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
// plugin that creates slider


// reactstrap components
import {
  Col,
  Container, Row, Button, Modal, Form, Input 
} from "reactstrap";


function Conduta() {
  const form = useRef();
  const form2 = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zek6hld', 'template_x29f9af', form.current, 'user_GdZsOdDQgfEwGqFwWbmKx')
      .then((result) => {
          alert("Seu relato foi enviado com sucesso!");
      }, (error) => {
        alert("Sistema indisponível! Tente novamente mais tarde.");
      });
  };

  const sendEmail2 = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zek6hld', 'template_a84hm69', form2.current, 'user_GdZsOdDQgfEwGqFwWbmKx')
      .then((result) => {
          alert("Seu relato foi enviado com sucesso!");
      }, (error) => {
        alert("Sistema indisponível! Tente novamente mais tarde.");
      });
  };

  const [liveDemo, setLiveDemo] = React.useState(false);
  const [liveDemo2, setLiveDemo2] = React.useState(false);
  return (
    <>
      <div style={{marginTop:150}}>
        <Container>
          <div style={{textAlign:"left", marginTop:50, marginBottom:20, fontSize:32, fontWeight:600}}>
          Conduta e Ética
          </div>
          <div style={{textAlign:"left", marginBottom:50, fontSize:16, fontWeight:400, lineHeight:2}}>
          <span style={{fontWeight:600}}>Prezados Colaboradores,</span><br /><br />

É com muita satisfação que divulgamos a <a href="/politicas" style={{fontWeight:600, color:"#02CFAA"}}>POLÍTICA DE CONDUTA E ÉTICA</a> aplicável a todo o GRUPO SEB, que passa a vigorar a partir de hoje.<br /><br />

Ela é de grande importância, pois reflete a preocupação com uma Gestão Ética. Seu objetivo é orientar as ações de todos os colaboradores e explicitar a postura da empresa em face dos diferentes públicos com os quais interage. É um instrumento que visa a inspirar as pessoas, um norteador em relação aos valores e à ética que devem permear o comportamento e as relações, tanto no âmbito interno quanto externo, sempre dentro do escopo e objetivos do Grupo SEB e respeito à sua Missão, Visão e Valores.<br /><br />

Os preceitos éticos devem balizar o nosso comportamento, de acordo com o que está descrito na Política de Conduta e Ética. Por isso, é importante entendermos que a ética é o conjunto de regras e preceitos de ordem valorativa e moral que se espera de todos nós, colaboradores do Grupo SEB.<br /><br />

Para que os objetivos sejam atingidos, cada um deve fazer a sua parte, aderindo às diretrizes e vivenciando as mesmas no dia a dia, em nossas ações, e relacionamentos.<br /><br />

Além disso, os preceitos de conduta e ética servem de parâmetro para a solução de problemas, e também de alicerce para a empresa no desvio de conduta de seus colaboradores, líderes, fornecedores e outros. Agregando valor, isso contribui para o fortalecimento da imagem do Grupo SEB e do seu reconhecimento na Comunidade como uma empresa de conduta ética.<br /><br />

<span style={{fontWeight:600}}>Os temas abordados são:</span><br />
• Ética e cidadania corporativa<br />
• Conduta com o Público de Relacionamento do Grupo SEB<br />
• Relacionamento com Fornecedores e Parceiros<br />
• Relacionamento com Colaboradores<br />
• Relacionamento com Clientes e Sociedade<br />
• Comissão de Compliance<br />
• Descumprimento desta Política<br /><br />

<span style={{fontWeight:600}}>
Atenciosamente,<br />
Diretoria Executiva<br /><br />
</span>

<span style={{fontWeight:600}}>
Situações de descumprimento desta Política de Conduta e Ética serão encaminhadas para análise Administrativa e apuração dos fatos e poderão ser informadas por meio do Canal Direto do Grupo SEB.
</span>
          </div>

          <div style={{textAlign:"left", marginTop:50, marginBottom:20, fontSize:32, fontWeight:600}}>
          Canal Direto Ouvidoria
          </div>

          <div style={{textAlign:"left", marginBottom:50, fontSize:16, fontWeight:400, lineHeight:2}}>
          Você pode fazer um relato de forma anônima ou se identificando. Incentivamos que seja identificado, para que possamos buscar mais informações com você, se necessário. Todas as informações são mantidas de forma segura e sigilosa. Você pode registrar um e-mail anônimo para manter contato conosco sem se identificar.
            </div>
            <div style={{textAlign:"left", marginBottom:50,}}>
            <Row>
              <Col sm>
              <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#013C4E", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  onClick={() => setLiveDemo(true)}                               
                >
                  Relato com identificação
                </Button>

    
              </Col>
              <Col sm>
              <Button
                  className="btn-round"
                  style={{color:"#fff", width:"100%", backgroundColor:"#013C4E", borderColor:"transparent", fontSize:14, textTransform:"none"}}
                  onClick={() => setLiveDemo2(true)}                               
                >
                  Relato Anônimo
                </Button>
              </Col>
            </Row>

            <Modal isOpen={liveDemo} toggle={() => setLiveDemo(false)}>
        <div style={{textAlign:"right", marginTop:20, marginRight:20}}>          
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setLiveDemo(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body" style={{textAlign:"center"}} >
          <p>
          <i className="nc-icon nc-alert-circle-i" style={{fontSize:30}} />
          </p>
          <p style={{fontSize:18, fontWeight:600}}>Canal Direto Ouvidoria</p>
          <p style={{fontSize:18, fontWeight:400}}>Relato com identificação</p>

          <hr />

          <div style={{padding:0, textAlign:"left"}}>
          <form ref={form} onSubmit={sendEmail}>

          <label style={{fontWeight:600}}>Nome</label>
          <Input bsSize="lg" type="text" name="nome" required="required"  /><br />
          <label style={{fontWeight:600}}>E-mail</label>
          <Input bsSize="lg" type="email" name="email" required="required" /><br />

          <label style={{fontWeight:600}}>CPF</label>
          <Input bsSize="lg" type="text" name="cpf" required="required" /><br />

          <label style={{fontWeight:600}}>Telefone</label>
          <Input bsSize="lg" type="text" name="telefone" required="required" /><br />

          <label style={{fontWeight:600}}>Relação com a empresa</label>
          <Input bsSize="lg" type="select" name="relacao" required="required">
          <option value="">
            Selecione...
          </option>
          <option value="Colaborador">
            Colaborador
          </option>
          <option value="Terceiro">
            Terceiro
          </option>
          <option value="Cliente">
            Cliente
          </option>
          <option value="Fornecedor">
            Fornecedor
          </option>
          <option value="Aluno">
            Aluno
          </option>
          <option value="Outros">
            Outros
          </option>
          </Input>  
            
            <br />
            <label style={{fontWeight:600}}>Nome do Aluno</label>
          <Input bsSize="lg" type="text" name="nome_aluno" required="required" /><br />

          <label style={{fontWeight:600}}>Segmento</label>
          <Input bsSize="lg" type="select" name="segmento" required="required">
          <option value="">
            Selecione...
          </option>
          <option value="Ensino Infantil">
          Ensino Infantil
          </option>
          <option value="Ensino Fundamental I">
          Ensino Fundamental I
          </option>
          <option value="Ensino Fundamental II">
          Ensino Fundamental II
          </option>
          <option value="Ensino Médio">
          Ensino Médio
          </option>
          <option value="Pré-Vestibular">
          Pré-Vestibular
          </option>
          <option value="Cursos Livres (Upselling)">
          Cursos Livres (Upselling)
          </option>
          <option value="Gração e Pós-Graduação">
          Gração e Pós-Graduação
          </option>
          </Input>  
            
            <br />

            <label style={{fontWeight:600}}>Série</label>
          <Input bsSize="lg" type="text" name="serie" required="required" /><br />

            <label style={{fontWeight:600}}>Turno</label>
          <Input bsSize="lg" type="select" name="turno" required="required">
          <option value="">
            Selecione...
          </option>
          <option value="Manhã">
          Manhã
          </option>
          <option value="Tarde">
          Tarde
          </option>
          <option value="Noite">
          Noite
          </option>
          <option value="Integral">
          Integral
          </option>         
          </Input>  
            
            <br />

     
            <label style={{fontWeight:600}}>Tipo de Relato</label>
          <Input bsSize="lg" type="select" name="tiporelato" required="required">
          <option value="">
            Selecione...
          </option>
          <option value="Conflito de Interesse">
          Conflito de Interesse
          </option>
          <option value="Fraude">
          Fraude
          </option>
          <option value="Saúde ou Segurança Operacional">
          Saúde ou Segurança Operacional
          </option>
          <option value="Relação com Terceiros">
          Relação com Terceiros
          </option>    

          <option value="Assédio Moral">
          Assédio Moral
          </option>
          <option value="Assédio Sexual">
          Assédio Sexual
          </option> 
          <option value="Agreção Física">
          Agreção Física
          </option> 
          <option value="Ameaça">
          Ameaça
          </option> 
          <option value="Desvio de  Função">
          Desvio de  Função
          </option> 
          <option value="Discriminação">
          Discriminação
          </option> 
          <option value="Importunação à Privacidade ou à Tranquilidade">
          Importunação à Privacidade ou à Tranquilidade
          </option> 
          <option value="Irregularidades em Contratos">
          Irregularidades em Contratos
          </option> 
          <option value="Nepotismo">
          Nepotismo
          </option> 
          <option value="Ofensa">
          Ofensa
          </option> 
          <option value="Recebimento / Oferencimento Indevido de Brindes">
          Recebimento / Oferencimento Indevido de Brindes
          </option> 
          <option value="Presentes e Hospitalidade">
          Presentes e Hospitalidade
          </option> 
          <option value="Retaliação">
          Retaliação
          </option> 
          <option value="Desvios de Materiais">
          Desvios de Materiais
          </option> 
          <option value="Desvio Financeiro">
          Desvio Financeiro
          </option> 
          <option value="Segurança nas Unidades">
          Segurança nas Unidades
          </option> 
          <option value="Segurança da Informação">
          Segurança da Informação
          </option> 
          <option value="Uso Indevido da Imagem e/ou Marca">
          Uso Indevido da Imagem e/ou Marca
          </option> 
          <option value="Uso de Substâncias Ilícitas">
          Uso de Substâncias Ilícitas
          </option> 
          <option value="Atendimento ruim">
          Atendimento ruim
          </option> 
          <option value="Soluções Financeiras/ Cobrança ">
          Soluções Financeiras/ Cobrança 
          </option>
          <option value="Outros">
          Outros
          </option>

          </Input>  
            
            <br />

            <label style={{fontWeight:600}}>Indique a Unidade/localidade onde ocorreu o fato que você está apresentando.</label>
          <Input bsSize="lg" type="select" name="unidade" required="required">
          <option value="">
            Selecione...
          </option>
          <option value="SEB Portugal">SEB Portugal</option>
<option value="SEB Ribeirânia">SEB Ribeirânia</option>
<option value="SEB Lafaiete">SEB Lafaiete</option>
<option value="SEB Thathi">SEB Thathi</option>
<option value="SEB Maceió">SEB Maceió</option>
<option value="SEB Dínatos">SEB Dínatos</option>
<option value="Vila Velha">Vila Velha</option>
<option value="Jardim da Penha">Jardim da Penha</option>
<option value="SEB Rio Preto">SEB Rio Preto</option>
<option value="Pueri Domus Verbo">Pueri Domus Verbo</option>
<option value="Pueri Domus Aclimação">Pueri Domus Aclimação</option>
<option value="Pueri Domus Itaim">Pueri Domus Itaim</option>
<option value="CECAN">CECAN</option>
<option value="Geo Sul">Geo Sul</option>
<option value="Geo Tambaú">Geo Tambaú</option>
<option value="COC Floripa - Continente">COC Floripa - Continente</option>
<option value="COC Floripa - PV">COC Floripa - PV</option>
<option value="COC Floripa - Centro">COC Floripa - Centro</option>
<option value="Colégio Visão">Colégio Visão</option>
<option value="Pueri Domus Perdizes">Pueri Domus Perdizes</option>
<option value="Sartre Graça">Sartre Graça</option>
<option value="Sartre Itaigara">Sartre Itaigara</option>
<option value="Sartre Monet I">Sartre Monet I</option>
<option value="Sartre Nobel">Sartre Nobel</option>
<option value="Sartre Monet II">Sartre Monet II</option>
<option value="Concept Salvador">Concept Salvador</option>
<option value="Unimaster Pré-Enem">Unimaster Pré-Enem</option>
<option value="Unimaster Jr">Unimaster Jr</option>
<option value="Colégio Einstein">Colégio Einstein</option>
<option value="Dom Bosco Ahú">Dom Bosco Ahú</option>
<option value="Dom Bosco Mueller">Dom Bosco Mueller</option>
<option value="Dom Bosco Mercês">Dom Bosco Mercês</option>
<option value="Dom Bosco CEI">Dom Bosco CEI</option>
<option value="Dom Bosco Batel">Dom Bosco Batel</option>
<option value="Conexia">Conexia</option>
<option value="Esfera">Esfera</option>
<option value="Concept Ribeirão">Concept Ribeirão</option>
<option value="Concept São Paulo">Concept São Paulo</option>
<option value="Luminova Sorocaba">Luminova Sorocaba</option>
<option value="Luminova Vila Prudente">Luminova Vila Prudente</option>
<option value="Luminova Bom Retiro">Luminova Bom Retiro</option>
<option value="Luminova Barra Funda">Luminova Barra Funda</option>
<option value="AaZ Botafogo 1">AaZ Botafogo 1</option>
<option value="AaZ Barra 1">AaZ Barra 1</option>
<option value="AaZ Botafogo 2">AaZ Botafogo 2</option>
<option value="AaZ Barra 2">AaZ Barra 2</option>
<option value="AaZ CSC Local">AaZ CSC Local</option>
<option value="AaZ Tijuca">AaZ Tijuca</option>
<option value="AaZ Recreio">AaZ Recreio</option>
<option value="AaZ Ipanema">AaZ Ipanema</option>
<option value="SEB Lafaiete CSC Local">SEB Lafaiete CSC Local</option>
<option value="ES CSC Local">ES CSC Local</option>
<option value="Pueri Domus CSC Local">Pueri Domus CSC Local</option>
<option value="Pueri Domus Diretoria Modelo">Pueri Domus Diretoria Modelo</option>
<option value="Carolina Patrício">Carolina Patrício</option>
<option value="Ekran">Ekran</option>
<option value="Geo CSC Local">Geo CSC Local</option>
<option value="COC Floripa - CSC Local">COC Floripa - CSC Local</option>
<option value="Sartre CSC Local">Sartre CSC Local</option>
<option value="Unimaster CSC Local">Unimaster CSC Local</option>
<option value="Dom Bosco CSC Local">Dom Bosco CSC Local</option>
<option value="Concept CSC Local">Concept CSC Local</option>
<option value="Luminova CSC Local">Luminova CSC Local</option>
<option value="Pueri Domus Aldeia">Pueri Domus Aldeia</option>
<option value="Faculdade Dom Bosco">Faculdade Dom Bosco</option>
<option value="EPD / Unyca">EPD / Unyca</option>
<option value="Faculdade Ipanema">Faculdade Ipanema</option>
<option value="CSC Nacional">CSC Nacional</option>
<option value="SEB Matriz">SEB Matriz</option>
<option value="CEFIC">CEFIC</option>
<option value="Maple Bear (MBC)">Maple Bear (MBC)</option>
<option value="Maple Bear FDMP (MBP)">Maple Bear FDMP (MBP)</option>
<option value="Dom Bosco Nova Batel/CSC">Dom Bosco Nova Batel/CSC</option>
<option value="Geo Prime">Geo Prime</option>
<option value="SEB Global Salvador">SEB Global Salvador</option>
<option value="TCA">TCA</option>
<option value="SEB Global BH">SEB Global BH</option>
<option value="Maple Bear Central">Maple Bear Central</option>
<option value="Maple Bear Franquiador">Maple Bear Franquiador</option>
<option value="Pueri Domus Show Room">Pueri Domus Show Room</option>
<option value="FAESC">FAESC</option>
<option value="Publiseb">Publiseb</option>
<option value="Alta Vela Novos Negócios">Alta Vela Novos Negócios</option>
<option value="Alta Vela Franquias">Alta Vela Franquias</option>
<option value="Alta Vela Educação">Alta Vela Educação</option>
<option value="Alta Vela Educação Básica">Alta Vela Educação Básica</option>
<option value="Luminova ADM">Luminova ADM</option>
<option value="EPD Liberdade">EPD Liberdade</option>
<option value="COLEGIO ESFERA">Colégio Esfera</option>
<option value="Conexia João Pessoa">Conexia João Pessoa</option>
          </Input>  
            
            <br />

            <label style={{fontWeight:600}}>Cidade</label>
          <Input bsSize="lg" type="text" name="cidade" required="required" /><br />

          <label style={{fontWeight:600}}>Estado</label>
          <Input bsSize="lg" type="text" name="estado" required="required" /><br />

          <label style={{fontWeight:600}}>Data do Fato</label>
          <Input bsSize="lg" type="text" name="datafato" required="required" /><br />

          <label style={{fontWeight:600}}>Descrição do relato</label>
          <Input bsSize="lg" type="textarea" style={{height:300}} name="descricao" required="required" /><br />

          <label style={{fontWeight:600}}>Você Sabe se algum sócio, diretor, gerente, coordenador ou qualquer membro da liderança está ciente do relatado? Quem (Especifique nome e sobrenome).</label>
          <Input bsSize="lg" type="textarea" style={{height:300}} name="pergunta1" required="required" /><br />

          <label style={{fontWeight:600}}>Há testemunhas dos fatos narrados? Em caso positivo, indique-as.</label>
          <Input bsSize="lg" type="textarea" style={{height:300}} name="pergunta2" required="required" /><br />

          <label style={{fontWeight:600}}>Há evidências que comprovem os fatos narrados? Em caso positivo, indiqueas.</label>
          <Input bsSize="lg" type="textarea" style={{height:300}} name="pergunta3" required="required" /><br />


          <input type="submit" value="Enviar" />
          </form>
        </div>


        </div>

       
      </Modal>



      <Modal isOpen={liveDemo2} toggle={() => setLiveDemo2(false)}>
        <div style={{textAlign:"right", marginTop:20, marginRight:20}}>          
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setLiveDemo2(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body" style={{textAlign:"center"}} >
          <p>
          <i className="nc-icon nc-alert-circle-i" style={{fontSize:30}} />
          </p>
          <p style={{fontSize:18, fontWeight:600}}>Canal Direto Ouvidoria</p>
          <p style={{fontSize:18, fontWeight:400}}>Relato sem identificação</p>

          <hr />

          <div style={{padding:0, textAlign:"left"}}>
          <form ref={form2} onSubmit={sendEmail2}>


          <label style={{fontWeight:600}}>E-mail anônimo</label>
          <Input bsSize="lg" type="email" name="email_anonimo" required="required" /><br />

         
          <label style={{fontWeight:600}}>Relação com a empresa</label>
          <Input bsSize="lg" type="select" name="relacao" required="required">
          <option value="">
            Selecione...
          </option>
          <option value="Colaborador">
            Colaborador
          </option>
          <option value="Terceiro">
            Terceiro
          </option>
          <option value="Cliente">
            Cliente
          </option>
          <option value="Fornecedor">
            Fornecedor
          </option>
          <option value="Aluno">
            Aluno
          </option>
          <option value="Outros">
            Outros
          </option>
          </Input>  
            
            <br />
            <label style={{fontWeight:600}}>Nome do Aluno</label>
          <Input bsSize="lg" type="text" name="nome_aluno" required="required" /><br />

          <label style={{fontWeight:600}}>Segmento</label>
          <Input bsSize="lg" type="select" name="segmento" required="required">
          <option value="">
            Selecione...
          </option>
          <option value="Ensino Infantil">
          Ensino Infantil
          </option>
          <option value="Ensino Fundamental I">
          Ensino Fundamental I
          </option>
          <option value="Ensino Fundamental II">
          Ensino Fundamental II
          </option>
          <option value="Ensino Médio">
          Ensino Médio
          </option>
          <option value="Pré-Vestibular">
          Pré-Vestibular
          </option>
          <option value="Cursos Livres (Upselling)">
          Cursos Livres (Upselling)
          </option>
          <option value="Gração e Pós-Graduação">
          Gração e Pós-Graduação
          </option>
          </Input>  
            
            <br />

            <label style={{fontWeight:600}}>Série</label>
          <Input bsSize="lg" type="text" name="serie" required="required" /><br />

            <label style={{fontWeight:600}}>Turno</label>
          <Input bsSize="lg" type="select" name="turno" required="required">
          <option value="">
            Selecione...
          </option>
          <option value="Manhã">
          Manhã
          </option>
          <option value="Tarde">
          Tarde
          </option>
          <option value="Noite">
          Noite
          </option>
          <option value="Integral">
          Integral
          </option>         
          </Input>  
            
            <br />

     
            <label style={{fontWeight:600}}>Tipo de Relato</label>
          <Input bsSize="lg" type="select" name="tiporelato" required="required">
          <option value="">
            Selecione...
          </option>
          <option value="Conflito de Interesse">
          Conflito de Interesse
          </option>
          <option value="Fraude">
          Fraude
          </option>
          <option value="Saúde ou Segurança Operacional">
          Saúde ou Segurança Operacional
          </option>
          <option value="Relação com Terceiros">
          Relação com Terceiros
          </option>    

          <option value="Assédio Moral">
          Assédio Moral
          </option>
          <option value="Assédio Sexual">
          Assédio Sexual
          </option> 
          <option value="Agreção Física">
          Agreção Física
          </option> 
          <option value="Ameaça">
          Ameaça
          </option> 
          <option value="Desvio de  Função">
          Desvio de  Função
          </option> 
          <option value="Discriminação">
          Discriminação
          </option> 
          <option value="Importunação à Privacidade ou à Tranquilidade">
          Importunação à Privacidade ou à Tranquilidade
          </option> 
          <option value="Irregularidades em Contratos">
          Irregularidades em Contratos
          </option> 
          <option value="Nepotismo">
          Nepotismo
          </option> 
          <option value="Ofensa">
          Ofensa
          </option> 
          <option value="Recebimento / Oferencimento Indevido de Brindes">
          Recebimento / Oferencimento Indevido de Brindes
          </option> 
          <option value="Presentes e Hospitalidade">
          Presentes e Hospitalidade
          </option> 
          <option value="Retaliação">
          Retaliação
          </option> 
          <option value="Desvios de Materiais">
          Desvios de Materiais
          </option> 
          <option value="Desvio Financeiro">
          Desvio Financeiro
          </option> 
          <option value="Segurança nas Unidades">
          Segurança nas Unidades
          </option> 
          <option value="Segurança da Informação">
          Segurança da Informação
          </option> 
          <option value="Uso Indevido da Imagem e/ou Marca">
          Uso Indevido da Imagem e/ou Marca
          </option> 
          <option value="Uso de Substâncias Ilícitas">
          Uso de Substâncias Ilícitas
          </option> 
          <option value="Atendimento ruim">
          Atendimento ruim
          </option> 
          <option value="Soluções Financeiras/ Cobrança ">
          Soluções Financeiras/ Cobrança 
          </option>
          <option value="Outros">
          Outros
          </option>

          </Input>  
            
            <br />

            <label style={{fontWeight:600}}>Indique a Unidade/localidade onde ocorreu o fato que você está apresentando.</label>
          <Input bsSize="lg" type="select" name="unidade" required="required">
          <option value="">
            Selecione...
          </option>

<option value="SEB Portugal">SEB Portugal</option>
<option value="SEB Ribeirânia">SEB Ribeirânia</option>
<option value="SEB Lafaiete">SEB Lafaiete</option>
<option value="SEB Thathi">SEB Thathi</option>
<option value="SEB Maceió">SEB Maceió</option>
<option value="SEB Dínatos">SEB Dínatos</option>
<option value="Vila Velha">Vila Velha</option>
<option value="Jardim da Penha">Jardim da Penha</option>
<option value="SEB Rio Preto">SEB Rio Preto</option>
<option value="Pueri Domus Verbo">Pueri Domus Verbo</option>
<option value="Pueri Domus Aclimação">Pueri Domus Aclimação</option>
<option value="Pueri Domus Itaim">Pueri Domus Itaim</option>
<option value="CECAN">CECAN</option>
<option value="Geo Sul">Geo Sul</option>
<option value="Geo Tambaú">Geo Tambaú</option>
<option value="COC Floripa - Continente">COC Floripa - Continente</option>
<option value="COC Floripa - PV">COC Floripa - PV</option>
<option value="COC Floripa - Centro">COC Floripa - Centro</option>
<option value="Colégio Visão">Colégio Visão</option>
<option value="Pueri Domus Perdizes">Pueri Domus Perdizes</option>
<option value="Sartre Graça">Sartre Graça</option>
<option value="Sartre Itaigara">Sartre Itaigara</option>
<option value="Sartre Monet I">Sartre Monet I</option>
<option value="Sartre Nobel">Sartre Nobel</option>
<option value="Sartre Monet II">Sartre Monet II</option>
<option value="Concept Salvador">Concept Salvador</option>
<option value="Unimaster Pré-Enem">Unimaster Pré-Enem</option>
<option value="Unimaster Jr">Unimaster Jr</option>
<option value="Colégio Einstein">Colégio Einstein</option>
<option value="Dom Bosco Ahú">Dom Bosco Ahú</option>
<option value="Dom Bosco Mueller">Dom Bosco Mueller</option>
<option value="Dom Bosco Mercês">Dom Bosco Mercês</option>
<option value="Dom Bosco CEI">Dom Bosco CEI</option>
<option value="Dom Bosco Batel">Dom Bosco Batel</option>
<option value="Conexia">Conexia</option>
<option value="Esfera">Esfera</option>
<option value="Concept Ribeirão">Concept Ribeirão</option>
<option value="Concept São Paulo">Concept São Paulo</option>
<option value="Luminova Sorocaba">Luminova Sorocaba</option>
<option value="Luminova Vila Prudente">Luminova Vila Prudente</option>
<option value="Luminova Bom Retiro">Luminova Bom Retiro</option>
<option value="Luminova Barra Funda">Luminova Barra Funda</option>
<option value="AaZ Botafogo 1">AaZ Botafogo 1</option>
<option value="AaZ Barra 1">AaZ Barra 1</option>
<option value="AaZ Botafogo 2">AaZ Botafogo 2</option>
<option value="AaZ Barra 2">AaZ Barra 2</option>
<option value="AaZ CSC Local">AaZ CSC Local</option>
<option value="AaZ Tijuca">AaZ Tijuca</option>
<option value="AaZ Recreio">AaZ Recreio</option>
<option value="AaZ Ipanema">AaZ Ipanema</option>
<option value="SEB Lafaiete CSC Local">SEB Lafaiete CSC Local</option>
<option value="ES CSC Local">ES CSC Local</option>
<option value="Pueri Domus CSC Local">Pueri Domus CSC Local</option>
<option value="Pueri Domus Diretoria Modelo">Pueri Domus Diretoria Modelo</option>
<option value="Carolina Patrício">Carolina Patrício</option>
<option value="Ekran">Ekran</option>
<option value="Geo CSC Local">Geo CSC Local</option>
<option value="COC Floripa - CSC Local">COC Floripa - CSC Local</option>
<option value="Sartre CSC Local">Sartre CSC Local</option>
<option value="Unimaster CSC Local">Unimaster CSC Local</option>
<option value="Dom Bosco CSC Local">Dom Bosco CSC Local</option>
<option value="Concept CSC Local">Concept CSC Local</option>
<option value="Luminova CSC Local">Luminova CSC Local</option>
<option value="Pueri Domus Aldeia">Pueri Domus Aldeia</option>
<option value="Faculdade Dom Bosco">Faculdade Dom Bosco</option>
<option value="EPD / Unyca">EPD / Unyca</option>
<option value="Faculdade Ipanema">Faculdade Ipanema</option>
<option value="CSC Nacional">CSC Nacional</option>
<option value="SEB Matriz">SEB Matriz</option>
<option value="CEFIC">CEFIC</option>
<option value="Maple Bear (MBC)">Maple Bear (MBC)</option>
<option value="Maple Bear FDMP (MBP)">Maple Bear FDMP (MBP)</option>
<option value="Dom Bosco Nova Batel/CSC">Dom Bosco Nova Batel/CSC</option>
<option value="Geo Prime">Geo Prime</option>
<option value="SEB Global Salvador">SEB Global Salvador</option>
<option value="TCA">TCA</option>
<option value="SEB Global BH">SEB Global BH</option>
<option value="Maple Bear Central">Maple Bear Central</option>
<option value="Maple Bear Franquiador">Maple Bear Franquiador</option>
<option value="Pueri Domus Show Room">Pueri Domus Show Room</option>
<option value="FAESC">FAESC</option>
<option value="Publiseb">Publiseb</option>
<option value="Alta Vela Novos Negócios">Alta Vela Novos Negócios</option>
<option value="Alta Vela Franquias">Alta Vela Franquias</option>
<option value="Alta Vela Educação">Alta Vela Educação</option>
<option value="Alta Vela Educação Básica">Alta Vela Educação Básica</option>
<option value="Luminova ADM">Luminova ADM</option>
<option value="EPD Liberdade">EPD Liberdade</option>
<option value="COLEGIO ESFERA">Colégio Esfera</option>
<option value="Conexia João Pessoa">Conexia João Pessoa</option>

          </Input>  
            
            <br />

            <label style={{fontWeight:600}}>Cidade</label>
          <Input bsSize="lg" type="text" name="cidade" required="required" /><br />

          <label style={{fontWeight:600}}>Estado</label>
          <Input bsSize="lg" type="text" name="estado" required="required" /><br />

          <label style={{fontWeight:600}}>Data do Fato</label>
          <Input bsSize="lg" type="text" name="datafato" required="required" /><br />

          <label style={{fontWeight:600}}>Descrição do relato</label>
          <Input bsSize="lg" type="textarea" style={{height:300}} name="descricao" required="required" /><br />

          <label style={{fontWeight:600}}>Você Sabe se algum sócio, diretor, gerente, coordenador ou qualquer membro da liderança está ciente do relatado? Quem (Especifique nome e sobrenome).</label>
          <Input bsSize="lg" type="textarea" style={{height:300}} name="pergunta1" required="required" /><br />

          <label style={{fontWeight:600}}>Há testemunhas dos fatos narrados? Em caso positivo, indique-as.</label>
          <Input bsSize="lg" type="textarea" style={{height:300}} name="pergunta2" required="required" /><br />

          <label style={{fontWeight:600}}>Há evidências que comprovem os fatos narrados? Em caso positivo, indiqueas.</label>
          <Input bsSize="lg" type="textarea" style={{height:300}} name="pergunta3" required="required" /><br />


          <input type="submit" value="Enviar" />
          </form>
        </div>


        </div>

       
      </Modal>

            </div>
        </Container>
       
</div>  


    </>
  );
}

export default Conduta;
